import Vue from 'vue'

import { library, dom } from '@apility/fontawesome-svg-core'
import { FontAwesomeIcon } from '@apility/vue-fontawesome'

import {
  faArrowLeft as fasArrowLeft,
  faBuilding as fasBuilding,
  faCalendarAlt as fasCalendarAlt,
  faCertificate as fasCertificate,
  faChartLine as fasChartLine,
  faCheck as fasCheck,
  faChevronDoubleDown as fasChevronDoubleDown,
  faCircleNotch as fasCircleNotch,
  faClipboardListCheck as fasClipboardListCheck,
  faColumns as fasColumns,
  faCommentPlus as fasCommentPlus,
  faCommentTimes as fasCommentTimes,
  faCopy as fasCopy,
  faDoNotEnter as fasDoNotEnter,
  faDownload as fasDownload,
  faEnvelopeOpenText as fasEnvelopeOpenText,
  faExclamationCircle as fasExclamationCircle,
  faFile as fasFile,
  faFileSignature as fasFileSignature,
  faFileEdit as fasFileEdit,
  faFileExport as fasFileExport,
  faFilePdf as fasFilePdf,
  faFilePlus as fasFilePlus,
  faFolders as fasFolders,
  faHandshakeAlt as fasHandshakeAlt,
  faHistory as fasHistory,
  faImages as fasImages,
  faInboxIn as fasInboxIn,
  faInboxOut as fasInboxOut,
  faKey as fasKey,
  faPaperPlane as fasPaperPlane,
  faPause as fasPause,
  faPlay as fasPlay,
  faPlus as fasPlus,
  faReply as fasReply,
  faSave as fasSave,
  faSearch as fasSearch,
  faSignOut as fasSignOut,
  faSync as fasSync,
  faTachometer as fasTachometer,
  faTimes as fasTimes,
  faTimesSquare as fasTimesSquare,
  faTrash as fasTrash,
  faUser as fasUser,
  faUserCircle as fasUserCircle,
  faUserLock as fasUserLock,
  faUserPlus as fasUserPlus,
  faUserTag as fasUserTag,
  faUserTimes as fasUserTimes,
  faSpinner as fasSpinner,
  faPencil as fasPencil,
  faUndo as fasUndo,
  faFilter as fasFilter,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faCommentAltSlash as farCommentAltSlash,
} from '@fortawesome/pro-regular-svg-icons'

Vue.component(FontAwesomeIcon.name, FontAwesomeIcon)

library.add(
  farCommentAltSlash,
  fasArrowLeft,
  fasBuilding,
  fasCalendarAlt,
  fasCertificate,
  fasChartLine,
  fasCheck,
  fasChevronDoubleDown,
  fasCircleNotch,
  fasClipboardListCheck,
  fasColumns,
  fasCommentPlus,
  fasCommentTimes,
  fasCopy,
  fasDoNotEnter,
  fasDownload,
  fasEnvelopeOpenText,
  fasExclamationCircle,
  fasFile,
  fasFileSignature,
  fasFileEdit,
  fasFileExport,
  fasFilePdf,
  fasFilePlus,
  fasFolders,
  fasHandshakeAlt,
  fasHistory,
  fasImages,
  fasInboxIn,
  fasInboxOut,
  fasKey,
  fasPaperPlane,
  fasPause,
  fasPlay,
  fasPlus,
  fasReply,
  fasSave,
  fasSearch,
  fasSignOut,
  fasSync,
  fasTachometer,
  fasTimes,
  fasTimesSquare,
  fasTrash,
  fasUser,
  fasUserCircle,
  fasUserLock,
  fasUserPlus,
  fasUserTag,
  fasUserTimes,
  fasSpinner,
  fasPencil,
  fasUndo,
  fasFilter,
)

dom.watch()
