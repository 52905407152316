<template>
  <BContainer fluid>
    <BRow>
      <BCol>
        <BCard
          class="DashboardCard"
          no-body
        >
          <BCardBody>
            <IssueDetailsStatusBar :issue="issue" />
          </BCardBody>
        </BCard>
      </BCol>
    </BRow>

    <BRow>
      <BCol md>
        <BCard
          class="DashboardCard"
          no-body
        >
          <BCardHeader>
            <h6>Beskrivelse</h6>
          </BCardHeader>

          <BCardBody>
            {{ issue.description }}
          </BCardBody>
        </BCard>

        <IssueDetailsComment
          :issue="issue"
        />

        <BCard
          class="DashboardCard"
          no-body
        >
          <BCardHeader>
            <h6>Bilder</h6>
          </BCardHeader>
          <BCardBody>
            <IssueDetailsImages
              :images="issue.images"
              :issue-id="issue.id"
            />
          </BCardBody>
        </BCard>

        <BCard
          class="DashboardCard"
          no-body
        >
          <BCardHeader>
            <h6>Saksdetaljer</h6>
          </BCardHeader>
          <BCardBody>
            <BTable
              striped
              :items="issueDetails"
              :fields="issueDetailsFields"
            >
              <template v-slot:cell(label)="data">
                <strong>{{ data.value }}</strong>
              </template>

              <template v-slot:cell(value)="data">
                <template v-if="data.item.key === 'projectStage'">
                  <a :href="`/projects/${data.value.id}/`">{{ data.value.name }}</a>
                </template>

                <template v-else-if="data.item.key === 'apartmentNumber'">
                  <a :href="`/apartments/${data.value.id}/`">{{ data.value.aptNumber }}</a>
                </template>

                <template v-else-if="data.item.key === 'residenceNumber'">
                  <a :href="`/apartments/${data.value.id}/`">{{ data.value.name }}</a>
                </template>

                <template v-else-if="data.item.key === 'category'">
                  {{ data.value.name }}
                </template>

                <template v-else-if="data.item.key === 'date'">
                  {{ formatDate(data.value) }}
                </template>

                <template v-else-if="data.item.key === 'closingTime'">
                  <ClosingTimeIndicator
                    :status="data.value.status"
                    :days="data.value.days"
                  />
                </template>

                <template v-else>
                  {{ data.value }}
                </template>
              </template>
            </BTable>
          </BCardBody>
        </BCard>
      </BCol>

      <BCol md>
        <BCard
          class="DashboardCard"
          no-body
        >
          <BCardHeader>
            <h6>Plantegning</h6>
          </BCardHeader>
          <BCardBody>
            <FloorPlan
              v-if="issue.aptImage"
              :image="issue.aptImage.path"
              :marker="issue.marker"
            />
          </BCardBody>
        </BCard>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import Dayjs from 'dayjs'

import ClosingTimeIndicator from '../components/ClosingTimeIndicator'
import FloorPlan from '@/shared/components/FloorPlan'
import IssueDetailsImages from '../components/IssueDetailsImages'
import IssueDetailsStatusBar from '../components/IssueDetailsStatusBar'
import IssueDetailsComment from '../components/IssueDetailsComment'

export default {
  components: {
    IssueDetailsComment,
    ClosingTimeIndicator,
    FloorPlan,
    IssueDetailsImages,
    IssueDetailsStatusBar,
  },

  props: {
    issue: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    issueDetailsFields: [
      {
        key: 'label',
        thStyle: {
          display: 'none',
        },
      },
      {
        key: 'value',
        thStyle: {
          display: 'none',
        },
      },
    ],
  }),

  computed: {
    issueDetails () {
      return [
        {
          key: 'projectStage',
          label: 'Byggetrinn',
          value: this.issue.projectStage,
        },
        {
          key: 'apartmentNumber',
          label: 'Leilighetsnummer',
          value: this.issue.apartment,
        },
        {
          key: 'residenceNumber',
          label: 'Bolignummer',
          value: this.issue.apartment,
        },
        {
          key: 'category',
          label: 'Kategori',
          value: this.issue.category,
        },
        {
          key: 'date',
          label: 'Dato',
          value: this.issue.caseDate,
        },
        {
          key: 'closingTime',
          label: 'Lukketid',
          value: this.issue.closed,
        },
        {
          key: 'customerName',
          label: 'Navn',
          value: this.issue.customerName,
        },
        {
          key: 'customerEmail',
          label: 'E-post',
          value: this.issue.customerMail,
        },
        {
          key: 'customerPhone',
          label: 'Mobil',
          value: this.issue.customerPhone,
        },
      ]
    },
  },

  methods: {
    formatDate (data) {
      return Dayjs(data).format('DD/MM/YYYY HH:mm')
    },
  },
}
</script>
