import { Store } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Import store modules here
import * as apartments from './apartments'
import * as superUsers from './superUsers'
import * as site from './site'
import * as settings from './settings'
import * as messages from './messages'
import * as issues from './issues'

export default new Store({
  strict: process.env.NODE_ENV !== 'production',

  modules: {
    apartments,
    messages,
    settings,
    site,
    superUsers,
    issues,
  },

  plugins: [createPersistedState({
    paths: [
      'apartments',
      'settings',
      'site',
      'superUsers',
      'issues',
    ],
  })],
})
