<template>
  <div>
    <IssueProviderCommunicationNewMessage :issue="issue" />
    <IssueMessages
      :issue="issue"
      type="provider"
    />
  </div>
</template>

<script>
import IssueProviderCommunicationNewMessage from '../components/IssueProviderCommunicationNewMessage'
import IssueMessages from '../components/IssueMessages'

export default {
  components: {
    IssueProviderCommunicationNewMessage,
    IssueMessages,
  },

  props: {
    issue: {
      type: Object,
      required: true,
    },
  },
}
</script>
