<template>
  <BContainer fluid>
    <BRow>
      <BCol md>
        <BCard
          class="DashboardCard"
          no-body
        >
          <BCardHeader>
            <h6>Eiere</h6>
          </BCardHeader>
          <BCardBody>
            <CustomerDetailsCard
              v-for="(owner, key) in owners"
              :key="key"
              :customer="owner"
              :apartment-id="apartment.id"
              :login-key="loginKey"
            />
          </BCardBody>
        </BCard>

        <BCard
          class="DashboardCard"
          no-body
        >
          <BCardHeader>
            <h6>Kjøpsdetaljer</h6>
          </BCardHeader>
          <BCardBody>
            <BTable
              striped
              fixed
              :items="purchaseDetails"
              :fields="apartmentDetailsFields"
              class="mb-0"
            >
              <template v-slot:cell(label)="data">
                <strong>{{ data.value }}</strong>
              </template>
            </BTable>
          </BCardBody>
        </BCard>

        <BCard
          class="DashboardCard"
          no-body
        >
          <BCardHeader>
            <h6>Leilighetsinformasjon</h6>
          </BCardHeader>
          <BCardBody>
            <BTable
              striped
              fixed
              :items="apartmentDetails"
              :fields="apartmentDetailsFields"
              class="mb-0"
            >
              <template v-slot:cell(label)="data">
                <strong>{{ data.value }}</strong>
              </template>

              <template v-slot:cell(value)="data">
                <template v-if="data.item.key === 'projectStage'">
                  <a :href="`/projects/${data.value.id}/`">{{ data.value.name }}</a>
                </template>

                <template v-else>
                  {{ data.value }}
                </template>
              </template>
            </BTable>
          </BCardBody>
        </BCard>
      </BCol>

      <BCol md>
        <BCard
          class="DashboardCard"
          no-body
        >
          <BCardHeader>
            <h6>Plantegning</h6>
          </BCardHeader>
          <BCardBody>
            <FloorPlan
              v-if="apartment.floorPlan"
              :image="apartment.floorPlan.path"
            />
          </BCardBody>
        </BCard>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import CustomerDetailsCard from '../components/CustomerDetailsCard'
import FloorPlan from '@/shared/components/FloorPlan'

export default {
  components: {
    CustomerDetailsCard,
    FloorPlan,
  },

  props: {
    apartment: {
      type: Object,
      required: true,
    },
    owners: {
      type: Array,
      required: true,
    },
    loginKey: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    apartmentDetailsFields: [
      {
        key: 'label',
        thStyle: {
          display: 'none',
        },
      },
      {
        key: 'value',
        thStyle: {
          display: 'none',
        },
      },
    ],
  }),

  computed: {
    apartmentDetails () {
      return [
        {
          key: 'projectStage',
          label: 'Byggetrinn',
          value: this.apartment.projectStage,
        },
        {
          key: 'aptNumber',
          label: 'Leilighetsnummer',
          value: this.apartment.aptNumber,
        },
        {
          key: 'name',
          label: 'Bolignummer',
          value: this.apartment.name,
        },
        {
          key: 'groundSize',
          label: 'BRA',
          value: this.apartment.groundSize,
        },
        {
          key: 'primarySize',
          label: 'P-rom',
          value: this.apartment.primarySize,
        },
        {
          key: 'aptOutdoorSize',
          label: 'Størrelse veranda',
          value: this.apartment.aptOutdoorSize,
        },
        {
          key: 'aptType',
          label: 'Leilighetstype',
          value: this.apartment.aptType,
        },
        {
          key: 'aptFloor',
          label: 'Etasje',
          value: this.apartment.aptFloor,
        },
        {
          key: 'aptRooms',
          label: 'Antall rom',
          value: this.apartment.aptRooms,
        },
        {
          key: 'aptSectionNumber',
          label: 'Seksjonsnummer',
          value: this.apartment.aptSectionNumber,
        },
        {
          key: 'aptSectionNumber2',
          label: 'Tillegsseksjonsnummer',
          value: this.apartment.aptSectionNumber2,
        },
        {
          key: 'aptSectionNumberParking',
          label: 'Seksjonsnummer parkeringsplass',
          value: this.apartment.aptSectionNumberParking,
        },
        {
          key: 'aptSectionNumberParking2',
          label: 'Seksjonsnummer parkeringsplass 2',
          value: this.apartment.aptSectionNumberParking2,
        },
        {
          key: 'aptSectionNumberStorage',
          label: 'Seksjonsnummer bod',
          value: this.apartment.aptSectionNumberStorage,
        },
      ]
    },

    purchaseDetails () {
      return [
        {
          key: 'purchaseDate',
          label: 'Kjøpsdato',
          value: this.apartment.purchaseDate,
        },
        {
          key: 'aptCost',
          label: 'Kjøpssum',
          value: this.apartment.aptCost,
        },
      ]
    },
  },
}
</script>
