import Axios from 'axios'

export const namespaced = true

export const state = {
  superUsers: [],
}

export const getters = {}

export const mutations = {
  SET_SUPER_USERS (state, superUsers) {
    state.superUsers = superUsers
  },
}

export const actions = {
  getAll ({ state, commit }, refresh = false) {
    if (state.superUsers.length && !refresh) {
      return state.superUsers
    }

    return Axios.get('/api/superUsers/').then(response => {
      commit('SET_SUPER_USERS', response.data.data)
      return state.superUsers
    }).catch(error => {
      console.error(error)
      return []
    })
  },

  async refresh ({ dispatch }) {
    dispatch('getAll', true)
  },
}
