<template>
  <BContainer fluid>
    <BRow>
      <BCol>
        <BTable
          responsive
          striped
          :fields="tableFields"
          :items="tableItems"
        >
          <template #cell(email)="data">
            <a
              :href="`mailto:${data.value}`"
              v-text="data.value"
            />
          </template>

          <template #cell(phoneNumber)="data">
            <a
              :href="`tel:${data.value}`"
              v-text="data.value"
            />
          </template>

          <template #cell(actions)="data">
            <span v-if="data.item.deleteable">
              <DeleteContactPersonButton
                :project-id="project.id"
                :contact-person-id="data.item.id"
                @contactPersonDeleted="onContactPersonDeleted"
              />
            </span>
          </template>
        </BTable>
      </BCol>
    </BRow>

    <BRow>
      <BCol>
        <BButton
          v-b-modal.create-contact-person-modal
          variant="success"
          class="mr-3"
        >
          <FontAwesomeIcon :icon="['fas', 'user-plus']" />
          Opprett ny
        </BButton>

        <BButton
          v-b-modal.add-contact-person-modal
          variant="primary"
        >
          <FontAwesomeIcon :icon="['fas', 'user-tag']" />
          Legg til eksisterende
        </BButton>

        <CreateContactPersonModal
          id="create-contact-person-modal"
          :project="project"
          @contactPersonCreated="onContactPersonAdded"
        />

        <AddContactPersonModal
          id="add-contact-person-modal"
          :project="project"
          @contactPersonAdded="onContactPersonAdded"
        />
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import AddContactPersonModal from '../components/AddContactPersonModal'
import CreateContactPersonModal from '../components/CreateContactPersonModal'
import DeleteContactPersonButton from '../components/DeleteContactPersonButton'

export default {
  components: {
    CreateContactPersonModal,
    DeleteContactPersonButton,
    AddContactPersonModal,
  },

  props: {
    project: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    tableFields: [
      {
        key: 'role',
        label: 'Rolle',
      },
      {
        key: 'name',
        label: 'Navn',
      },
      {
        key: 'company',
        label: 'Selskap',
      },
      {
        key: 'email',
        label: 'E-postadresse',
      },
      {
        key: 'phoneNumber',
        label: 'Telefonnummer',
      },
      {
        key: 'actions',
        label: '',
        tdClass: 'text-right',
      },
    ],
    tableItems: [],
  }),

  mounted () {
    this.tableItems = this.project.contactPersons
  },

  methods: {
    onContactPersonAdded (event) {
      this.tableItems.push(event.data.data)
    },

    onContactPersonDeleted (event) {
      this.tableItems = event.data.data
    },
  },
}
</script>
