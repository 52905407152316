import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../config/vuex/store'

import ApartmentDetailsView from '../views/ApartmentDetailsView'
import ApartmentIssuesView from '../views/ApartmentIssuesView'
import ApartmentMasterView from '../views/ApartmentMasterView'
import ApartmentOneYearInspectionView from '../views/ApartmentOneYearInspectionView.vue'
import ApartmentTakeoverView from '../views/ApartmentTakeoverView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:id/oneYearInspection',
    component: ApartmentOneYearInspectionView,
    name: 'ApartmentOneYearInspectionView',
    props: true,
  },
  {
    path: '/:id/takeover',
    component: ApartmentTakeoverView,
    name: 'ApartmentTakeoverView',
    props: true,
  },
  {
    path: '/:id',
    component: ApartmentMasterView,
    props: true,
    children: [
      {
        path: '/',
        name: 'ApartmentDetailsView',
        component: ApartmentDetailsView,
        props: true,
      },
      {
        path: 'issues',
        name: 'ApartmentIssuesView',
        component: ApartmentIssuesView,
        props: true,
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/apartments',
  routes,
})

const apartmentPageMountElement = document.querySelector('.ApartmentPage')

if (apartmentPageMountElement) {
  new Vue({
    name: 'ApartmentPageRoot',

    store,
    router,
  }).$mount(apartmentPageMountElement)
}
