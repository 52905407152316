<template>
  <BNavForm
    class="NavbarSearch"
    @submit.prevent="onSubmit"
  >
    <VbTypeahead
      ref="typeahead"
      v-model="query"
      class="NavbarSearch__typeAheadWrapper"
      input-class="NavbarSearch__input"
      :placeholder="placeholder"
      :data="typeaheadData"
      :serializer="item => item.label"
      @hit="goToItem"
    >
      <template #prepend>
        <BInputGroupText v-if="isSearching">
          <BSpinner
            variant="secondary"
            small
          />
        </BInputGroupText>
        <BInputGroupText v-else>
          <FontAwesomeIcon :icon="['fas', 'search']" />
        </BInputGroupText>
      </template>

      <template #suggestion="{ data, htmlText }">
        <div class="d-flex justify-content-between align-items-center">
          <span v-html="htmlText" />

          <BBadge
            v-if="data.type === 'apartment'"
            variant="info"
          >
            <FontAwesomeIcon :icon="['fas', 'key']" />
            Leilighet
          </BBadge>
          <BBadge
            v-else-if="data.type === 'issue'"
            variant="danger"
          >
            <FontAwesomeIcon :icon="['fas', 'file']" />
            Sak
          </BBadge>
          <BBadge
            v-else-if="data.type === 'project'"
            variant="success"
          >
            <FontAwesomeIcon :icon="['fas', 'building']" />
            Prosjekt
          </BBadge>
        </div>
      </template>
    </VbTypeahead>
  </BNavForm>
</template>

<script>
import VbTypeahead from 'vue-bootstrap-typeahead'
import { debounce } from 'debounce'
import Axios from 'axios'

export default {
  components: {
    VbTypeahead,
  },

  props: {
    placeholder: {
      type: String,
      default: null,
    },
    buttonLabel: {
      type: String,
      default: 'Søk',
    },
  },

  data: () => ({
    query: '',
    typeaheadData: [],
    isSearching: false,
    currentType: null,
  }),

  watch: {
    query () {
      this.searchDebounced()
    },
  },

  methods: {
    search () {
      this.isSearching = true
      this.typeaheadData = []

      Axios.get(`/api/search/?q=${this.query}`).then(response => {
        this.typeaheadData = response.data.data
        this.isSearching = false
      }).catch(error => {
        console.error(error)
        this.isSearching = false
      })
    },

    searchDebounced: debounce(function () {
      this.search()
    }, 200),

    goToItem (item) {
      window.location.href = item.url
    },

    goToFirstResult () {
      if (this.typeaheadData.length) {
        this.goToItem(this.typeaheadData[0])
      }
    },

    onSubmit () {
      if (this.typeaheadData.length) {
        this.goToFirstResult()
      } else {
        this.search()
      }
    },
  },
}
</script>
