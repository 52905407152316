<template>
  <div>
    <BButton
      :id="`popover-type-${$_uid}`"
      :size="buttonSize"
      :variant="buttonVariant"
      :block="buttonBlock"
    >
      {{ type.name }}
    </BButton>

    <BPopover
      ref="popover"
      :target="`popover-type-${$_uid}`"
      triggers="click blur"
      placement="top"
      boundary="window"
      @show="popoverShow"
      @shown="popoverShown"
    >
      <BForm
        inline
        @submit.prevent="submitForm"
      >
        <BInputGroup>
          <BFormSelect
            ref="formSelect"
            v-model="selectedType"
            :disabled="isBusy"
          >
            <option
              v-for="type in availableTypes"
              :key="type.key"
              :value="type"
            >
              {{ type.name }}
            </option>
          </BFormSelect>

          <BInputGroupAppend>
            <BButton
              v-if="isBusy"
              disabled
            >
              <b-spinner
                variant="light"
                small
              />
            </BButton>
            <BButton
              v-else
              type="submit"
              variant="primary"
            >
              Lagre
            </BButton>
          </BInputGroupAppend>
        </BInputGroup>
      </BForm>
    </BPopover>
  </div>
</template>

<script>
import Axios from 'axios'
export default {
  props: {
    currentType: {
      type: [Object, String],
      default: null,
    },
    issueId: {
      type: [Number, String],
      required: true,
    },
    buttonVariant: {
      type: String,
      default: 'light-gray',
    },
    buttonSize: {
      type: String,
      default: 'sm',
    },
    buttonBlock: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    type: {
      key: null,
      name: null,
    },
    selectedType: null,
    isBusy: true,
    availableTypes: [],
    popoverShownBefore: false,
  }),

  mounted () {
    this.type = this.currentType
    this.selectedType = this.currentType
  },

  methods: {
    popoverShow () {
      if (!this.popoverShownBefore || !this.availableTypes.length) {
        this.popoverShownBefore = true

        this.getTypes()
      }
    },

    popoverShown () {
      const formSelect = this.$refs.formSelect

      if (formSelect) {
        formSelect.$el.focus()
      }
    },

    submitForm () {
      if (this.selectedType && this.selectedType !== this.type) {
        this.isBusy = true

        Axios.put(`/api/issues/${this.issueId}/`, {
          caseType: this.selectedType.key,
        }).then(response => {
          this.type = this.selectedType
          this.$refs.popover.$emit('close')
        }).finally(response => {
          this.isBusy = false
        })
      }
    },

    async getTypes () {
      await this.$store.dispatch('issues/getTypes')

      this.availableTypes = this.$store.state.issues.types
      this.isBusy = false
    },
  },
}
</script>
