<template>
  <BContainer fluid>
    <BRow>
      <BCol>
        <BCard no-body>
          <BCardBody>
            <BContainer fluid>
              <BRow>
                <BCol md="8">
                  <h6 class="mb-3">
                    Send melding til kunde
                  </h6>

                  <BForm @submit.prevent="sendMessage">
                    <BFormGroup
                      :id="`input-group-message-${$_uid}`"
                      label="Melding"
                      :label-for="`input-message-${$_uid}`"
                    >
                      <ckeditor
                        :id="`input-message-${$_uid}`"
                        v-model="form.message"
                        :editor="editor.editor"
                        :config="editor.config"
                        :disabled="isSubmitting"
                        @ready="onEditorReady"
                      />
                    </BFormGroup>

                    <BFormGroup
                      :id="`input-group-attachments-${$_uid}`"
                      label="Legg til vedlegg"
                      :label-for="`input-attachments-${$_uid}`"
                    >
                      <BFormFile
                        :id="`input-attachments-${$_uid}`"
                        v-model="form.attachments"
                        placeholder="Trykk for å velge filer eller dra og slipp her…"
                        drop-placeholder="Slipp fil(er) her…"
                        multiple
                        :disabled="isSubmitting"
                      />
                    </BFormGroup>

                    <BFormGroup
                      :id="`input-group-deadline-${$_uid}`"
                      label="Legg til oppfølgingsfrist"
                      :label-for="`input-deadline-${$_uid}`"
                    >
                      <BFormSelect
                        :id="`input-deadline-${$_uid}`"
                        v-model="form.deadline"
                        :options="form.deadlineOptions"
                        :disabled="isSubmitting"
                      />
                    </BFormGroup>

                    <BFormGroup>
                      <BFormCheckbox
                        v-model="form.sendCopyToService"
                        :disabled="isSubmitting"
                      >
                        Send kopi til service@vestaksen.no
                      </BFormCheckbox>
                    </BFormGroup>

                    <BFormGroup>
                      <BFormCheckbox
                        v-model="form.includePdfLink"
                        :disabled="isSubmitting"
                      >
                        Legg til lenke til service ordre PDF på slutten av meldingen
                      </BFormCheckbox>
                    </BFormGroup>

                    <template v-if="isSubmitting">
                      <BButton
                        variant="primary"
                        disabled
                      >
                        <BSpinner
                          variant="light"
                          small
                        />
                        Sender…
                      </BButton>
                      <BFormText class="mt-2">
                        Merk at dersom du har mange eller store filvedlegg kan dette ta litt tid.
                      </BFormText>
                    </template>

                    <BButton
                      v-else
                      variant="primary"
                      type="submit"
                    >
                      <FontAwesomeIcon :icon="['fas', 'paper-plane']" />
                      Send melding til kunde
                    </BButton>
                  </BForm>
                </BCol>
                <BCol md="4">
                  <h6 class="my-3 mt-md-0">
                    Verktøy
                  </h6>

                  <MessageStandardReplies
                    :message="form.message"
                    type="client"
                    @apply="applyStandardReply"
                  />
                </BCol>
              </BRow>
            </BContainer>
          </BCardBody>
        </BCard>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import Axios from 'axios'
import EventBus from '@/shared/config/eventBus'
import MessageStandardReplies from '../components/MessageStandardReplies'
import CKEditorClassic from '@ckeditor/ckeditor5-build-classic'

export default {
  components: {
    MessageStandardReplies,
  },

  props: {
    issue: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    editor: {
      editor: CKEditorClassic,
      config: {
        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'indent', 'outdent', '|', 'undo', 'redo'],
      },
    },
    form: {
      message: '',
      attachments: null,
      sendCopyToService: false,
      includePdfLink: true,
      deadline: null,
      deadlineOptions: [
        { value: null, text: 'Ingen' },
        { value: '24hours', text: '24 timer' },
        { value: '48hours', text: '48 timer' },
        { value: '72hours', text: '72 timer' },
        { value: '7days', text: '7 dager' },
        { value: '14days', text: '14 dager' },
      ],
    },
    isSubmitting: false,
  }),

  activated () {
    if (this.editor.ready) {
      this.replaceMessageFromRouteParam()
    }

    EventBus.$on('copyIssueMessageToMessageField', this.onCopyMessage)
  },

  deactivated () {
    EventBus.$off('copyIssueMessageToMessageField', this.onCopyMessage)
  },

  methods: {
    applyStandardReply (value) {
      this.form.message = value
    },

    sendMessage () {
      this.isSubmitting = true

      const formData = new FormData()
      formData.append('messageType', 'customer')
      formData.append('issue', this.issue.id)
      formData.append('message', this.form.message)

      if (this.form.deadline) {
        formData.append('deadline', this.form.deadline)
      }

      if (this.form.sendCopyToService) {
        formData.append('sendCopyToService', this.form.sendCopyToService)
      }

      if (this.form.includePdfLink) {
        formData.append('includePdfLink', 'true')
      }

      if (this.form.attachments && this.form.attachments.length) {
        this.form.attachments.forEach(function (file, index) {
          formData.append(`files[${index}]`, file)
        })
      }

      Axios.post('/api/messages/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
        this.isSubmitting = false
        this.clearForm()

        this.$bvToast.toast('Meldingen ble sendt', {
          variant: 'success',
          title: 'Melding sendt',
        })
      }).catch(error => {
        this.isSubmitting = false

        console.error(error)
      })
    },

    clearForm () {
      this.form.message = ''
      this.form.attachments = null
      this.form.sendCopyToService = false
      this.form.deadline = null
    },

    onCopyMessage (data) {
      if (data.to === 'customer') {
        this.replaceMessage(data.message)
      }
    },

    replaceMessage (message) {
      this.form.message = message

      this.$bvToast.toast('Meldingen ble kopiert til meldingsfeltet', {
        variant: 'success',
        title: 'Melding kopiert',
      })

      window.scrollTo(0, 0)
    },

    replaceMessageFromRouteParam () {
      if (typeof this.$route.params.message !== 'undefined') {
        this.replaceMessage(this.$route.params.message)
      }
    },

    onEditorReady () {
      this.editor.ready = true

      this.replaceMessageFromRouteParam()
    },
  },
}
</script>
