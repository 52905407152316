<template>
  <BContainer fluid>
    <BRow
      v-if="images"
      class="IssueDetailsImages"
    >
      <BCol
        v-for="(image, key) in computedImages"
        :key="key"
        class="IssueDetailsImages__col"
      >
        <template v-if="image">
          <a
            :href="`https://${cdnUrl}/media/o/${image.path}`"
            target="_blank"
          >
            <BImgLazy
              thumbnail
              fluid
              :src="`https://${cdnUrl}/media/rc/250x250/${image.path}`"
              class="IssueDetailsImages__image"
              :alt="image.description"
            />
          </a>
          <div class="IssueDetailsImages__buttons">
            <BButton
              variant="outline-secondary"
              size="sm"
              :href="`https://${cdnUrl}/media/o/${image.path}?download`"
              download
              target="_blank"
              class="IssueDetailsImages__button"
            >
              <FontAwesomeIcon :icon="['fas', 'download']" />
              Last ned
            </BButton>

            <BButton
              variant="outline-danger"
              size="sm"
              class="IssueDetailsImages__button"
              :disabled="deleteButtonsDisabled"
              @click="onDeleteImageClick(image)"
            >
              <FontAwesomeIcon
                :icon="image.file === deletingImage ? ['fas', 'spinner'] : ['fas', 'trash']"
                :pulse="image.file === deletingImage"
              />

              Slett
            </BButton>
          </div>
        </template>
      </BCol>
    </BRow>

    <BRow v-else>
      <BCol>
        <BAlert
          variant="warning"
          show
        >
          Kunden har ikke lastet opp noen bilder til denne saken.
        </BAlert>
      </BCol>
    </BRow>

    <BRow>
      <BCol class="d-flex flex-wrap m-n2">
        <BButton
          v-if="sentImageRequest"
          class="m-2"
          variant="success"
          disabled
        >
          <FontAwesomeIcon :icon="['fas', 'check']" />
          Forespørsel sendt
        </BButton>

        <BButton
          v-else
          class="m-2"
          variant="primary"
          :disabled="sendingImageRequest"
          @click="sendImageRequest"
        >
          <BSpinner
            v-if="sendingImageRequest"
            variant="light"
            small
          />
          <FontAwesomeIcon
            v-else
            :icon="['fas', 'images']"
          />
          Be om flere bilder
        </BButton>

        <BFormFile
          ref="formFile"
          class="w-auto m-2"
          accept="image/jpeg, image/png"
          placeholder="Last opp bilder"
          :browse-text="formFileBrowseText"
          drop-placeholder="Slipp filer her"
          :file-name-formatter="formatFileNames"
          multiple
          :disabled="formFileDisabled"
          @input="onFileSelect"
        />
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import Axios from 'axios'

export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
    issueId: {
      type: [String, Number],
      required: true,
    },
  },

  data: () => ({
    sendingImageRequest: false,
    sentImageRequest: false,

    newImages: [],
    submittingNewImages: false,

    deletedImages: [],
    deletingImage: false,
  }),

  computed: {
    cdnUrl () {
      return this.$store.state.site.cdnUrl
    },

    computedImages: ({ images, newImages, deletedImages }) => (
      /*
       * This is an unfortunate solution, but it doesn't require reloading to display changes.
       * As the issue data is passed in as a prop to a parent component, it's immutable, will require a full reload to update.
       * Had it been implemented in vuex, using a package like vuex-hydra, it would be much simpler.
       */
      [...images, ...newImages]
        .filter((image) => (!deletedImages.includes(image.file)))
    ),

    formFileBrowseText: ({ submittingNewImages }) => (
      submittingNewImages
        ? 'Laster opp bilder'
        : 'Bla gjennom'
    ),

    formFileDisabled: ({ submittingNewImages }) => (
      submittingNewImages
    ),

    deleteButtonsDisabled: ({ deletingImage }) => (
      /*
       * We have to disable all delete buttons, as several deletions can't happen at once.
       * This is a limitation in the backend.
       */
      deletingImage
    ),
  },

  methods: {
    sendImageRequest () {
      this.sendingImageRequest = true

      Axios.post(`/api/issues/${this.issueId}/requestImages`).then(response => {
        this.sentImageRequest = true
      }).catch(error => {
        this.$bvToast.toast('Forespørsel ble ikke sendt.', {
          variant: 'danger',
          title: 'Noe gikk galt',
        })

        console.error(error)
      }).finally(response => {
        this.sendingImageRequest = false
      })
    },

    formatFileNames (files) {
      if (files.length === 1) {
        return files[0].name
      } else {
        return `${files.length} filer valgt`
      }
    },

    onFileSelect (files) {
      if (files.length > 0) {
        this.submitNewImages(files)
      }
    },

    async submitNewImages (images) {
      const formData = new FormData()

      images.forEach((image, index) => {
        formData.append(`files[${this.issueId}][${index}]`, image)
      })

      this.submittingNewImages = true

      try {
        const { data: { data: newImages } } = await Axios.post(`/api/issues/${this.issueId}/addImages`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        this.newImages = [
          ...this.newImages,
          ...newImages,
        ]
      } finally {
        this.$refs.formFile.reset()

        this.submittingNewImages = false
      }
    },

    onDeleteImageClick (image) {
      this.deleteImage(image)
    },

    async deleteImage (image) {
      try {
        this.deletingImage = image.file

        await Axios.delete(`/api/issues/${this.issueId}/deleteImage/${image.file}`)

        this.deletedImages = [
          ...this.deletedImages,
          image.file,
        ]
      } finally {
        this.deletingImage = false
      }
    },
  },
}
</script>
