const base = {
  applyLabel: 'Velg',
  cancelLabel: 'Avbryt',
  weekLabel: 'U',
  customRangeLabel: 'Egendefinert periode',
  daysOfWeek: ['Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør', 'Søn'],
  monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'],
  firstDay: 1,
}

const dateFormat = {
  format: 'DD. MMMM YYYY',
  ...base,
}

const dateAndTimeFormat = {
  format: 'DD-MM-YYYY HH:mm',
  ...base,
}

export { dateFormat, dateAndTimeFormat }

export default dateFormat
