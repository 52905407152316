<template>
  <BContainer fluid>
    <template v-if="isLoading">
      <div class="SpinnerBlock SpinnerBlock--large">
        <BSpinner
          class="SpinnerBlock__spinner"
          variant="secondary"
        />
        <span class="SpinnerBlock__label">Laster meldinger…</span>
      </div>
    </template>
    <template v-else-if="!messages.length">
      <BRow>
        <BCol class="text-center text-muted py-5">
          <FontAwesomeIcon
            size="3x"
            :icon="['far', 'comment-alt-slash']"
          />
          <h4 class="mt-4">
            Det er ingen meldinger i denne kategorien.
          </h4>
        </BCol>
      </BRow>
    </template>
    <template
      v-for="(message, index) in messages"
      v-else
    >
      <BRow :key="index + '-divider'">
        <BCol>
          <hr>
        </BCol>
      </BRow>
      <BRow :key="index + '-message'">
        <BCol md="8">
          <IssueMessageCard :message="message" />
        </BCol>
        <BCol md="4">
          <h6>Valg</h6>
          <BButton
            class="mt-2"
            variant="outline-dark"
            block
            @click="copyMessageToMessageField(message.message, 'provider')"
          >
            <FontAwesomeIcon :icon="['fas', 'copy']" />
            Kopier til leverandørmelding
          </BButton>
          <BButton
            class="mt-2"
            variant="outline-dark"
            block
            @click="copyMessageToMessageField(message.message, 'customer')"
          >
            <FontAwesomeIcon :icon="['fas', 'copy']" />
            Kopier til meldingsfelt
          </BButton>
        </BCol>
      </BRow>
    </template>
  </BContainer>
</template>

<script>
import EventBus from '@/shared/config/eventBus'
import IssueMessageCard from '@/shared/components/IssueMessageCard'

export default {
  components: {
    IssueMessageCard,
  },

  props: {
    issue: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'customer',
    },
  },

  data: () => ({
    isLoading: true,
    messages: [],
  }),

  mounted () {
    this.getMessages()
  },

  methods: {
    async getMessages () {
      await this.$store.dispatch('messages/getMessagesByIssue', this.issue.id).then(messages => {
        this.messages = messages[this.type]
        this.isLoading = false
      })
    },

    copyMessageToMessageField (message, type) {
      if (type !== this.type) {
        const routes = {
          customer: 'IssueCustomerCommunicationView',
          provider: 'IssueProviderCommunicationView',
        }

        this.$router.push({
          name: routes[type],
          params: {
            message,
          },
        })
      } else {
        EventBus.$emit('copyIssueMessageToMessageField', {
          to: type,
          message,
        })
      }
    },
  },
}
</script>
