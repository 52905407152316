<template>
  <BContainer fluid>
    <BRow>
      <BCol>
        <BCard no-body>
          <BCardBody>
            <BContainer fluid>
              <BRow>
                <BCol md="8">
                  <h6 class="mb-3">
                    Send melding til entreprenør
                  </h6>

                  <BForm @submit.prevent="sendMessage">
                    <BFormGroup
                      :id="`input-group-recipients-${$_uid}`"
                      label="Mottakere"
                      :label-for="`input-recipients-${$_uid}`"
                    >
                      <VueMultiselect
                        v-model="form.recipients"
                        :options="recipientsOptions"
                        label="label"
                        track-by="email"
                        multiple
                        :disabled="recipientsAreLoading"
                        :loading="recipientsAreLoading"
                        :placeholder="recipientsAreLoading ? 'Laster…' : 'Velg mottakere'"
                        select-label="Trykk enter for å velge"
                        select-group-label="Trykk enter for å velge gruppe"
                        selected-label="Valgt"
                        deselect-label="Trykk enter for å fjerne"
                        deselect-group-label="Trykk enter for å fjerne grupp"
                        :limit-text="count => `og ${count} til`"
                        :close-on-select="false"
                      />
                    </BFormGroup>

                    <BFormGroup
                      :id="`input-group-message-${$_uid}`"
                      label="Melding"
                      :label-for="`input-message-${$_uid}`"
                    >
                      <ckeditor
                        :id="`input-message-${$_uid}`"
                        v-model="form.message"
                        :editor="editor.editor"
                        :config="editor.config"
                        :disabled="isSubmitting"
                        @ready="onEditorReady"
                      />
                    </BFormGroup>

                    <BFormGroup
                      :id="`input-group-deadline-${$_uid}`"
                      label="Legg til oppfølgingsfrist"
                      :label-for="`input-deadline-${$_uid}`"
                    >
                      <BFormSelect
                        :id="`input-deadline-${$_uid}`"
                        v-model="form.deadline"
                        :options="form.deadlineOptions"
                        :disabled="isSubmitting"
                      />
                    </BFormGroup>

                    <BFormGroup>
                      <BFormCheckbox
                        v-model="form.sendCopyToService"
                        :disabled="isSubmitting"
                      >
                        Send kopi til service@vestaksen.no
                      </BFormCheckbox>
                    </BFormGroup>

                    <template v-if="isSubmitting">
                      <BButton
                        variant="primary"
                        disabled
                      >
                        <BSpinner
                          variant="light"
                          small
                        />
                        Sender…
                      </BButton>
                    </template>

                    <BButton
                      v-else
                      variant="primary"
                      type="submit"
                      :disabled="submitDisabled"
                    >
                      <FontAwesomeIcon :icon="['fas', 'paper-plane']" />
                      Send melding til entreprenør
                    </BButton>
                  </BForm>
                </BCol>
                <BCol md="4">
                  <h6 class="my-3 mt-md-0">
                    Verktøy
                  </h6>

                  <MessageStandardReplies
                    :message="form.message"
                    type="provider"
                    @apply="applyStandardReply"
                  />
                </BCol>
              </BRow>
            </BContainer>
          </BCardBody>
        </BCard>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import Axios from 'axios'
import CKEditorClassic from '@ckeditor/ckeditor5-build-classic'
import EventBus from '@/shared/config/eventBus'
import MessageStandardReplies from '../components/MessageStandardReplies'
import VueMultiselect from 'vue-multiselect'

export default {
  components: {
    MessageStandardReplies,
    VueMultiselect,
  },

  props: {
    issue: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    editor: {
      editor: CKEditorClassic,
      config: {
        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'indent', 'outdent', '|', 'undo', 'redo'],
      },
      ready: false,
    },
    form: {
      recipients: null,
      message: '',
      sendCopyToService: false,
      deadline: null,
      deadlineOptions: [
        { value: null, text: 'Ingen' },
        { value: '24hours', text: '24 timer' },
        { value: '48hours', text: '48 timer' },
        { value: '72hours', text: '72 timer' },
        { value: '7days', text: '7 dager' },
        { value: '14days', text: '14 dager' },
      ],
    },
    recipients: [],
    recipientsAreLoading: true,
    isSubmitting: false,
  }),

  computed: {
    recipientsOptions () {
      return this.recipients.map(recipient => {
        return {
          email: recipient.email,
          label: `${recipient.name} (${recipient.email})`,
        }
      })
    },

    submitDisabled () {
      return this.form.recipients === null || !this.form.recipients.length
    },
  },

  mounted () {
    this.getRecipients()
  },

  activated () {
    if (this.editor.ready) {
      this.replaceMessageFromRouteParam()
    }

    EventBus.$on('copyIssueMessageToMessageField', this.onCopyMessage)
  },

  deactivated () {
    EventBus.$off('copyIssueMessageToMessageField', this.onCopyMessage)
  },

  methods: {
    applyStandardReply (value) {
      this.form.message = value
    },

    sendMessage () {
      if (!this.submitDisabled) {
        this.isSubmitting = true

        const formData = new FormData()
        formData.append('messageType', 'provider')
        formData.append('issue', this.issue.id)
        formData.append('message', this.form.message)

        this.form.recipients.forEach((recipient, index) => {
          formData.append(`recipients[${index}][mail]`, recipient.email)
          formData.append(`recipients[${index}][name]`, recipient.label)
        })

        if (this.form.deadline) {
          formData.append('deadline', this.form.deadline)
        }

        if (this.form.sendCopyToService) {
          formData.append('sendCopyToService', this.form.sendCopyToService)
        }

        Axios.post('/api/messages/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(response => {
          this.isSubmitting = false
          this.clearForm()

          this.$bvToast.toast('Meldingen ble sendt', {
            variant: 'success',
            title: 'Melding sendt',
          })
        }).catch(error => {
          this.isSubmitting = false

          console.error(error)
        })
      }
    },

    clearForm () {
      this.form.recipients = null
      this.form.message = ''
      this.form.sendCopyToService = false
      this.form.deadline = null
    },

    onCopyMessage (data) {
      if (data.to === 'provider') {
        this.replaceMessage(data.message)
      }
    },

    replaceMessage (message) {
      this.form.message = message

      this.$bvToast.toast('Meldingen ble kopiert til meldingsfeltet', {
        variant: 'success',
        title: 'Melding kopiert',
      })

      window.scrollTo(0, 0)
    },

    replaceMessageFromRouteParam () {
      if (typeof this.$route.params.message !== 'undefined') {
        this.replaceMessage(this.$route.params.message)
      }
    },

    onEditorReady () {
      this.editor.ready = true

      this.replaceMessageFromRouteParam()
    },

    async getRecipients () {
      await this.$store.dispatch('messages/getProviderRecipientsByIssue', this.issue.id).then(recipients => {
        this.recipients = recipients
        this.recipientsAreLoading = false
      })
    },
  },
}
</script>
