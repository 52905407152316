<template>
  <div>
    <BTable
      :id="`issue-deadlines-table-${$_uid}`"
      ref="deadlinesTable"
      striped
      :busy.sync="isBusy"
      :items="dataProvider"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      responsive
      show-empty
    >
      <template v-slot:table-busy>
        <div class="text-center my-4">
          <BSpinner class="d-block mx-auto mb-2" />
          <strong>Laster…</strong>
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:empty="scope">
        <BAlert
          variant="info"
          class="my-4"
          show
        >
          <p v-if="completed">
            Det er ingen fullførte oppgaver på denne saken.
          </p>
          <p v-else>
            Det er ingen kommende frister på denne saken.
          </p>
        </BAlert>
      </template>

      <template v-slot:cell(date)="data">
        {{ formatDate(data.value) }}
      </template>

      <template v-slot:cell(markAsComplete)="data">
        <IssueDeadlinesMarkAsCompleteButton :deadline-id="data.item.id" />
      </template>
    </BTable>
  </div>
</template>

<script>
import Axios from 'axios'
import buildQueryString from '@/shared/utils/buildQueryString'
import Dayjs from 'dayjs'
import EventBus from '@/shared/config/eventBus'
import IssueDeadlinesMarkAsCompleteButton from './IssueDeadlinesMarkAsCompleteButton'

export default {
  components: {
    IssueDeadlinesMarkAsCompleteButton,
  },

  props: {
    issueId: {
      type: [String, Number],
      required: true,
    },
    completed: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isBusy: false,
    perPage: 25,
    currentPage: 1,
    totalItems: 0,
    fields: [
      {
        key: 'description',
        label: 'Beskrivelse',
        sortable: true,
      },
      {
        key: 'responsibleUser',
        label: 'Ansvarlig',
        sortable: true,
      },
      {
        key: 'date',
        label: 'Frist',
        sortable: true,
      },
    ],
  }),

  mounted () {
    if (!this.completed) {
      this.fields.push({
        key: 'markAsComplete',
        label: '',
        sortable: false,
      })
    }

    EventBus.$on(['deadlineCreated', 'deadlineUpdated'], this.refreshData)
  },

  methods: {
    dataProvider (ctx) {
      let query = {}
      query.page = ctx.currentPage

      if (ctx.sortBy) {
        query.sortBy = ctx.sortBy
        query.sortDirection = ctx.sortDesc ? 'desc' : 'asc'
      }

      if (this.completed) {
        query.completed = true
      }

      query = this.buildQueryString(query)

      const request = Axios.get(`/api/deadlines/issue/${this.issueId}/?${query}`)

      return request.then(response => {
        const data = response.data.data
        const items = data.items

        this.totalItems = data.totalItems
        this.perPage = data.itemsPerPage
        this.currentPage = data.page + 1

        return items
      }).catch(error => {
        this.$bvToast.toast('Kunne ikke hente frister.', {
          variant: 'danger',
          title: 'Noe gikk galt',
        })

        console.error(error)

        return []
      })
    },

    buildQueryString (data) {
      return buildQueryString(data)
    },

    refreshData () {
      this.$refs.deadlinesTable.refresh()
    },

    formatDate (data) {
      return Dayjs(data).format('DD/MM/YYYY HH:mm')
    },
  },
}
</script>
