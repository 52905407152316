<template>
  <VueSignature
    ref="signature"
    class="SignatureField"
    v-bind="$attrs"
    color="black"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'SignatureField',

  methods: {
    clear () {
      this.$refs.signature.clear()
    },
  },
}
</script>
