<template>
  <BContainer fluid>
    <BRow>
      <BCol>
        <ApartmentsTable
          :table-id="`apartments-table-project-${project.id}`"
          :filters="tableFilters"
          :hide-fields="['projectStage']"
        />
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import ApartmentsTable from '../components/ApartmentsTable'

export default {
  components: {
    ApartmentsTable,
  },

  props: {
    project: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      tableFilters: {
        projectStage: this.project.id,
      },
    }
  },
}
</script>
