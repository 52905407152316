<template>
  <BContainer fluid>
    <BRow>
      <BCol>
        <IssueDeadlinesActions
          :issue="issue"
        />
      </BCol>
    </BRow>

    <BRow>
      <BCol>
        <IssueDeadlinesTable
          :issue-id="issue.id"
        />
      </BCol>
    </BRow>

    <BRow>
      <BCol>
        <hr class="my-5">
        <h4 class="mb-4">
          Fullførte oppgaver
        </h4>

        <IssueDeadlinesTable
          :issue-id="issue.id"
          completed
        />
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import IssueDeadlinesActions from '../components/IssueDeadlinesActions'
import IssueDeadlinesTable from '../components/IssueDeadlinesTable'

export default {
  components: {
    IssueDeadlinesActions,
    IssueDeadlinesTable,
  },

  props: {
    issue: {
      type: Object,
      required: true,
    },
  },
}
</script>
