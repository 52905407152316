<template>
  <div class="Page__mainView">
    <BContainer fluid>
      <BRow>
        <BCol
          cols="12"
          md="8"
        >
          <h1 class="Page__mainHeading">
            <FontAwesomeIcon
              class="Page__mainHeadingIcon"
              :icon="['fas', 'file']"
            />
            Sak #{{ issue.id }}
            <small class="Page__mainHeadingSub">
              {{ issue.projectStage.name }}
            </small>
          </h1>
        </BCol>
        <BCol
          cols="12"
          md="4"
        >
          <div class="MasterActions">
            <BDropdown
              class="MasterActions__button"
              variant="primary"
              right
            >
              <template v-slot:button-content>
                <FontAwesomeIcon :icon="['fas', 'download']" />
                Last ned
              </template>

              <BDropdownHeader>
                Sak
              </BDropdownHeader>
              <BDropdownItem
                download
                :href="`/status/${issue.name}.pdf`"
              >
                <FontAwesomeIcon :icon="['fas', 'file-pdf']" />
                Last ned som PDF
              </BDropdownItem>
            </BDropdown>
          </div>
        </BCol>
      </BRow>
    </BContainer>

    <section class="MasterTabs">
      <nav class="MasterTabs__navWrapper">
        <BNav
          tabs
          class="MasterTabs__nav"
        >
          <BNavItem
            v-for="tab in tabs"
            :key="tab.name"
            :to="{name: tab.name}"
            class="MasterTabs__tab"
            :exact="true"
            exact-active-class="active"
          >
            {{ tab.label }}
          </BNavItem>
        </BNav>
      </nav>

      <div class="MasterTabs__content">
        <RouterView
          v-bind="[$props, $attrs]"
        />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    issue: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    tabs: [
      {
        name: 'IssueDetailsView',
        label: 'Detaljer',
      },
      {
        name: 'IssueHistoryView',
        label: 'Historikk',
      },
      {
        name: 'IssueDeadlinesView',
        label: 'Frister',
      },
      {
        name: 'IssueCustomerCommunicationView',
        label: 'Kommunikasjon med kunde',
      },
      {
        name: 'IssueProviderCommunicationView',
        label: 'Kommunikasjon med entreprenør',
      },
      {
        name: 'IssueInternalNotesView',
        label: 'Interne notater',
      },
    ],
  }),
}
</script>
