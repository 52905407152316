<template>
  <BContainer fluid>
    <BRow>
      <BCol>
        <IssuesTable
          :table-id="`issues-table-apartment-${apartment.id}`"
          :filters="{'apartment': apartment.id}"
          :hide-fields="['apartment']"
        />
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import IssuesTable from '../components/IssuesTable'

export default {
  components: {
    IssuesTable,
  },

  props: {
    apartment: {
      type: Object,
      required: true,
    },
  },
}
</script>
