import Vue from 'vue'

import store from '../config/vuex/store'

import DashboardMessageCard from '../components/DashboardMessageCard'
import NewIssuesTable from '../components/NewIssuesTable'

Vue.component('dashboard-message-card', DashboardMessageCard)
Vue.component('new-issues-table', NewIssuesTable)

const dashboardPageMountElement = document.querySelector('.DashboardPage')

if (dashboardPageMountElement) {
  new Vue({
    name: 'DashboardPageRoot',

    store,
  }).$mount(dashboardPageMountElement)
}
