import Vue from 'vue'

import store from '../config/vuex/store'

import ProjectsTable from '../components/ProjectsTable'

const projectsPageMountElement = document.querySelector('.ProjectsPage')

Vue.component('projects-table', ProjectsTable)

if (projectsPageMountElement) {
  new Vue({
    name: 'ProjectsPageRoot',

    store,
  }).$mount(projectsPageMountElement)
}
