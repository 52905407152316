import Axios from 'axios'

export const namespaced = true

export const state = {
  statuses: [],
  types: [],
  fields: [],
  responsiblePersons: [],
  categories: [],
}

export const mutations = {
  SET_STATUSES (state, payload) {
    state.statuses = payload
  },

  SET_TYPES (state, payload) {
    state.types = payload
  },

  SET_FIELDS (state, payload) {
    state.fields = payload
  },

  SET_RESPONSIBLE_PERSONS (state, payload) {
    state.responsiblePersons = payload
  },

  SET_CATEGORIES (state, payload) {
    state.categories = payload
  },
}

export const actions = {
  async getStatuses ({ state, commit }, refresh = false) {
    if (state.statuses?.length && !refresh) {
      return
    }

    const response = await Axios.get('/api/issues/statuses')

    commit('SET_STATUSES', response.data.data)
  },

  async getTypes ({ state, commit }, refresh = false) {
    if (state.types?.length && !refresh) {
      return
    }

    const response = await Axios.get('/api/issues/types')

    commit('SET_TYPES', response.data.data)
  },

  async getFields ({ state, commit }, refresh = false) {
    if (state.fields?.length && !refresh) {
      return
    }

    const response = await Axios.get('/api/issues/fields')

    commit('SET_FIELDS', response.data.data)
  },

  async getResponsiblePersons ({ state, commit }, refresh = false) {
    if (state.responsiblePersons?.length && !refresh) {
      return
    }

    const response = await Axios.get('/api/contactPersons')

    commit('SET_RESPONSIBLE_PERSONS', response.data.data)
  },

  async getCategories ({ state, commit }, refresh = false) {
    if (state.categories?.length && !refresh) {
      return
    }

    const response = await Axios.get('/api/issues/categories')

    commit('SET_CATEGORIES', response.data.data)
  },

  async refresh ({ dispatch }) {
    await dispatch('getStatuses', true)
    await dispatch('getTypes', true)
    await dispatch('getFields', true)
    await dispatch('getResponsiblePersons', true)
    await dispatch('getCategories', true)
  },
}
