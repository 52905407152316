<template>
  <div class="Page__mainView">
    <BContainer fluid>
      <BRow>
        <BCol
          cols="12"
          md="8"
        >
          <h1 class="Page__mainHeading">
            <FontAwesomeIcon
              class="Page__mainHeadingIcon"
              :icon="['fas', 'key']"
            />
            {{ apartment.aptNumber }}
            <small class="Page__mainHeadingSub">
              {{ apartment.projectStage.name }}
            </small>
          </h1>
        </BCol>
        <BCol
          cols="12"
          md="4"
        >
          <div class="MasterActions">
            <BDropdown
              class="MasterActions__button"
              variant="primary"
              right
            >
              <template #button-content>
                <FontAwesomeIcon :icon="['fas', 'file-signature']" />
                Dokumenter
              </template>

              <BDropdownItem :to="{ name: 'ApartmentTakeoverView' }">
                Overtakelsesprotokoll
              </BDropdownItem>

              <BDropdownItem :to="{ name: 'ApartmentOneYearInspectionView' }">
                Ettårsprotokoll
              </BDropdownItem>
            </BDropdown>
          </div>
        </BCol>
      </BRow>
    </BContainer>

    <section class="MasterTabs">
      <nav class="MasterTabs__navWrapper">
        <BNav
          tabs
          class="MasterTabs__nav"
        >
          <BNavItem
            v-for="tab in tabs"
            :key="tab.name"
            :to="{name: tab.name}"
            class="MasterTabs__tab"
            :exact="true"
            exact-active-class="active"
          >
            {{ tab.label }}
          </BNavItem>
        </BNav>
      </nav>

      <div class="MasterTabs__content">
        <RouterView
          v-bind="[$props, $attrs]"
        />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    apartment: {
      type: Object,
      required: true,
    },
    owners: {
      type: Array,
      required: true,
    },
    issuesCount: {
      type: [String, Number],
      default: 0,
    },
    loginKey: {
      type: String,
      default: null,
    },
  },

  data: () => ({
  }),

  computed: {
    tabs () {
      return [
        {
          name: 'ApartmentDetailsView',
          label: 'Detaljer',
        },
        {
          name: 'ApartmentIssuesView',
          label: `Saker (${this.issuesCount})`,
        },
      ]
    },
  },
}
</script>
