<template>
  <BModal
    ref="add-contact-person-modal"
    v-bind="[$props, $attrs]"
    :title="`Legg til kontaktperson`"
  >
    <BForm
      ref="form"
      @submit.prevent="submitForm(hideModal)"
      @keydown.enter="submitForm(hideModal)"
    >
      <BFormGroup
        :id="`input-group-person-${$_uid}`"
        label="Velg kontaktperson"
        :label-for="`input-person-${$_uid}`"
      >
        <BFormSelect
          :id="`input-person-${$_uid}`"
          v-model="form.selected"
          :options="options"
          required
          :disabled="isSubmitting || isLoading"
        />
      </BFormGroup>
    </BForm>

    <template v-slot:modal-footer="{ ok, cancel }">
      <BButton
        variant="secondary"
        :disabled="isSubmitting"
        @click="cancel()"
      >
        Avbryt
      </BButton>

      <BButton
        v-if="isSubmitting"
        variant="primary"
        disabled
      >
        <BSpinner
          variant="light"
          small
        />
      </BButton>

      <BButton
        v-else
        :disabled="isLoading"
        variant="primary"
        @click="submitForm(ok)"
      >
        Legg til
      </BButton>
    </template>
  </BModal>
</template>

<script>
import Axios from 'axios'

export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    form: {
      selected: null,
    },
    contactPersons: [],
    isSubmitting: false,
    isLoading: true,
  }),

  computed: {
    options () {
      if (this.contactPersons.length > 0) {
        return [
          {
            value: null,
            text: 'Velg kontaktperson',
            disabled: true,
          },

          ...this.contactPersons.map(person => {
            return {
              value: person.id,
              text: `${person.name} (${person.email})`,
            }
          }),
        ]
      } else {
        return [
          {
            value: null,
            text: 'Laster…',
          },
        ]
      }
    },
  },

  mounted () {
    this.getContactPersons()
  },

  methods: {
    async getContactPersons () {
      await Axios.get('/api/contactPersons/').then(response => {
        this.contactPersons = response.data.data
        this.isLoading = false
      })
    },

    clearFormData () {
      this.form.selected = null
    },

    hideModal () {
      this.$refs['add-contact-person-modal'].hide()
    },

    submitForm (callback) {
      if (this.$refs.form.reportValidity()) {
        this.isSubmitting = true

        Axios.put(`/api/projects/${this.project.id}/contactPersons/${this.form.selected}`, this.form).then(response => {
          this.$bvToast.toast('Kontaktpersonen ble lagt til.', {
            variant: 'success',
            title: 'Kontaktperson lagt til',
          })

          this.$emit('contactPersonAdded', response)
          this.isSubmitting = false
          this.clearFormData()
          callback()
        }).catch(error => {
          this.isSubmitting = false

          this.$bvToast.toast('Kunne ikke legge til kontaktperson.', {
            variant: 'danger',
            title: 'Noe gikk galt',
          })

          console.error(error)
        })
      }
    },
  },
}
</script>
