<template>
  <div class="Page__mainView TakeoverProtocol">
    <BContainer fluid>
      <BRow>
        <BCol
          cols="12"
          md="7"
          lg="5"
          offset-md="1"
          offset-lg="2"
        >
          <h1 class="Page__mainHeading">
            <FontAwesomeIcon
              class="Page__mainHeadingIcon"
              :icon="['fas', 'calendar-alt']"
            />
            {{ apartment.aptNumber }}
            <small class="Page__mainHeadingSub Page__mainHeadingSub--block">
              Protokoll fra ettårsbefaring
            </small>
          </h1>
        </BCol>
        <BCol
          cols="12"
          md="3"
          lg="3"
        >
          <div class="MasterActions">
            <BButton
              class="MasterActions__button"
              variant="link"
              :to="{ name: 'ApartmentDetailsView' }"
            >
              <FontAwesomeIcon :icon="['fas', 'arrow-left']" />
              Tilbake
            </BButton>
            <BDropdown
              class="MasterActions__button"
              variant="primary"
            >
              <template #button-content>
                <FontAwesomeIcon :icon="['fas', 'plus']" />
                Ny sak
              </template>

              <BDropdownGroup header="Logg inn som">
                <BDropdownItem
                  v-for="(owner, key) in owners"
                  :key="key"
                  :href="`https://minside.vestaksen.no/loginAsCustomer/${owner.id}/${apartment.id}/?key=${loginKey}`"
                  target="_blank"
                >
                  {{ owner.firstname }} {{ owner.surname }}
                </BDropdownItem>
              </BDropdownGroup>
            </BDropdown>
          </div>
        </BCol>
      </BRow>

      <BRow>
        <BCol
          cols="12"
          md="10"
          lg="8"
          offset-md="1"
          offset-lg="2"
        >
          <BForm @submit.prevent="submitForm">
            <BCard class="FormSectionCard">
              <template #header>
                <h2 class="FormSectionCard__heading">
                  Befaring
                </h2>
              </template>

              <BFormGroup
                :id="`input-group-inspectionDate-${$_uid}`"
                class="FormSectionCard__formGroup"
                label="Ettårsbefaring avholdt"
                :label-for="`input-inspectionDate-${$_uid}`"
              >
                <DateRangePicker
                  ref="datePicker"
                  v-model="form.inspectionDate"
                  single-date-picker
                  show-dropdowns
                  :ranges="null"
                  :locale-data="dateRangePickerLocale"
                >
                  <template #input="data">
                    <BInputGroup>
                      <template #prepend>
                        <BInputGroupText>
                          <FontAwesomeIcon :icon="['fas', 'calendar-alt']" />
                        </BInputGroupText>
                      </template>

                      <BFormInput
                        :id="`input-inspectionDate-${$_uid}`"
                        :value="formatDate(data.startDate)"
                      />
                    </BInputGroup>
                  </template>
                </DateRangePicker>
              </BFormGroup>
            </BCard>

            <BCard class="FormSectionCard">
              <template #header>
                <h2 class="FormSectionCard__heading">
                  Tilstede
                </h2>
              </template>

              <h6 class="mb-2">
                Tilstede for eier
              </h6>

              <template v-if="form.presentForCustomer.length > 0">
                <BCard
                  v-for="(item, key) in form.presentForCustomer"
                  :key="key"
                  class="FormSectionCard FormSectionCard--subSection"
                  tag="fieldset"
                >
                  <BRow>
                    <BCol md="6">
                      <BFormGroup
                        :id="`input-group-customerName-${key}`"
                        class="FormSectionCard__formGroup mb-3 mb-md-0"
                        :label-for="`input-customerName-${key}`"
                        label="Navn"
                      >
                        <BFormInput
                          :id="`input-customerName-${key}`"
                          v-model="form.presentForCustomer[key].name"
                        />
                      </BFormGroup>
                    </BCol>

                    <BCol
                      md="6"
                      class="d-flex align-items-end"
                    >
                      <BFormGroup
                        :id="`input-group-customerContactInformation-${key}`"
                        class="FormSectionCard__formGroup mb-0 flex-grow-1"
                        :label-for="`input-customerContactInformation-${key}`"
                        label="Telefon/e-post"
                      >
                        <BFormInput
                          :id="`input-customerContactInformation-${key}`"
                          v-model.number="form.presentForCustomer[key].contactInformation"
                        />
                      </BFormGroup>

                      <div class="flex-grow-0 flex-shrink-1 ml-4">
                        <BButton
                          variant="outline-danger"
                          @click="deletePresentCustomer(key)"
                        >
                          <span class="sr-only">Fjern</span>
                          <FontAwesomeIcon :icon="['fas', 'trash']" />
                        </BButton>
                      </div>
                    </BCol>
                  </BRow>
                </BCard>
              </template>

              <div
                v-else
                class="text-muted my-3"
              >
                Det er ikke lagt til noen eiere.
              </div>

              <BButton
                variant="success"
                @click="addPresentCustomer"
              >
                <FontAwesomeIcon :icon="['fas', 'plus']" />
                Legg til
              </BButton>

              <hr>

              <h6 class="mb-2">
                Tilstede for utbygger
              </h6>

              <BCard
                class="FormSectionCard FormSectionCard--subSection"
                tag="fieldset"
              >
                <BRow>
                  <BCol md="6">
                    <BFormGroup
                      :id="`input-group-sellerCompany-${$_uid}`"
                      class="FormSectionCard__formGroup mb-3 mb-md-0"
                      :label-for="`input-sellerCompany-${$_uid}`"
                      label="Selskap"
                    >
                      <BFormInput
                        :id="`input-sellerCompany-${$_uid}`"
                        v-model="form.presentForSeller.company"
                      />
                    </BFormGroup>
                  </BCol>

                  <BCol md="6">
                    <BFormGroup
                      :id="`input-group-sellerRepresentative-${$_uid}`"
                      class="FormSectionCard__formGroup mb-0 flex-grow-1"
                      :label-for="`input-sellerRepresentative-${$_uid}`"
                      label="Representant"
                    >
                      <BFormInput
                        :id="`input-sellerRepresentative-${$_uid}`"
                        v-model.number="form.presentForSeller.representative"
                      />
                    </BFormGroup>
                  </BCol>
                </BRow>
              </BCard>

              <hr>

              <h6 class="mb-2">
                Tilstede for entreprenør
              </h6>

              <BCard
                class="FormSectionCard FormSectionCard--subSection"
                tag="fieldset"
              >
                <BRow>
                  <BCol md="6">
                    <BFormGroup
                      :id="`input-group-entrepreneurCompany-${$_uid}`"
                      class="FormSectionCard__formGroup mb-3 mb-md-0"
                      :label-for="`input-entrepreneurCompany-${$_uid}`"
                      label="Selskap"
                    >
                      <BFormInput
                        :id="`input-entrepreneurCompany-${$_uid}`"
                        v-model="form.presentForEntrepreneur.company"
                      />
                    </BFormGroup>
                  </BCol>

                  <BCol md="6">
                    <BFormGroup
                      :id="`input-group-entrepreneurRepresentative-${$_uid}`"
                      class="FormSectionCard__formGroup mb-0 flex-grow-1"
                      :label-for="`input-entrepreneurRepresentative-${$_uid}`"
                      label="Representant"
                    >
                      <BFormInput
                        :id="`input-entrepreneurRepresentative-${$_uid}`"
                        v-model.number="form.presentForEntrepreneur.representative"
                      />
                    </BFormGroup>
                  </BCol>
                </BRow>
              </BCard>
            </BCard>

            <BCard class="FormSectionCard">
              <template #header>
                <h2 class="FormSectionCard__heading">
                  Signering
                </h2>
              </template>

              <BFormGroup
                :id="`input-group-sign-location-${$_uid}`"
                class="FormSectionCard__formGroup"
                label="Sted"
                :label-for="`input-sign-location-${$_uid}`"
              >
                <BFormInput
                  :id="`input-sign-location-${$_uid}`"
                  v-model="form.sign.location"
                />
              </BFormGroup>

              <BFormGroup
                :id="`input-group-sign-date-${$_uid}`"
                class="FormSectionCard__formGroup"
                label="Dato"
                :label-for="`input-sign-date-${$_uid}`"
              >
                <DateRangePicker
                  ref="datePicker"
                  v-model="form.sign.date"
                  single-date-picker
                  show-dropdowns
                  :ranges="null"
                  :locale-data="dateRangePickerLocale"
                >
                  <template #input="data">
                    <BInputGroup>
                      <template #prepend>
                        <BInputGroupText>
                          <FontAwesomeIcon :icon="['fas', 'calendar-alt']" />
                        </BInputGroupText>
                      </template>

                      <BFormInput
                        :id="`input-sign-date-${$_uid}`"
                        :value="formatDate(data.startDate)"
                      />
                    </BInputGroup>
                  </template>
                </DateRangePicker>
              </BFormGroup>

              <BRow class="m-n3">
                <BCol
                  v-for="customerSignature in form.sign.customerSignatures"
                  :key="customerSignature.name"
                  class="p-3"
                  xl="6"
                >
                  <BFormGroup
                    :id="`input-group-sign-customer-${customerSignature.name.replace(/\s/, '-')}-${$_uid}`"
                    class="FormSectionCard__formGroup"
                    :label="customerSignature.name"
                    :label-for="`input-sign-customer-${customerSignature.name.replace(/\s/, '-')}-${$_uid}`"
                  >
                    <SignatureField
                      :id="`input-sign-customer-${customerSignature.name.replace(/\s/, '-')}-${$_uid}`"
                      :ref="`customerSignature-${customerSignature.name.replace(/\s/, '-')}`"
                      v-model="customerSignature.signature"
                    />

                    <BButton
                      class="mt-2"
                      variant="outline-secondary"
                      @click="$refs[`customerSignature-${customerSignature.name.replace(/\s/, '-')}`][0].clear()"
                    >
                      <FontAwesomeIcon :icon="['fas', 'undo']" />

                      Tilbakestill
                    </BButton>
                  </BFormGroup>
                </BCol>
              </BRow>

              <BRow class="m-n3">
                <BCol
                  class="p-3"
                  xl="6"
                >
                  <div class="d-inline-block">
                    <BFormGroup
                      :id="`input-group-sign-seller-${$_uid}`"
                      class="FormSectionCard__formGroup"
                      :label="`Utbygger ${form.presentForSeller.representative}`"
                      :label-for="`input-sign-seller-${$_uid}`"
                    >
                      <SignatureField
                        ref="sellerSignature"
                        v-model="form.sign.sellerSignature"
                        class="mt-2"
                      />

                      <BButton
                        class="mt-2"
                        variant="outline-secondary"
                        @click="$refs.sellerSignature.clear()"
                      >
                        <FontAwesomeIcon :icon="['fas', 'undo']" />

                        Tilbakestill
                      </BButton>
                    </BFormGroup>
                  </div>
                </BCol>

                <BCol
                  class="p-3"
                  xl="6"
                >
                  <div class="d-inline-block">
                    <BFormGroup
                      :id="`input-group-sign-entrepreneur-${$_uid}`"
                      class="FormSectionCard__formGroup"
                      :label="`Entreprenør ${form.presentForEntrepreneur.representative}`"
                      :label-for="`input-sign-entrepreneur-${$_uid}`"
                    >
                      <SignatureField
                        ref="entrepreneurSignature"
                        v-model="form.sign.entrepreneurSignature"
                        class="mt-2"
                      />

                      <BButton
                        class="mt-2"
                        variant="outline-secondary"
                        @click="$refs.entrepreneurSignature.clear()"
                      >
                        <FontAwesomeIcon :icon="['fas', 'undo']" />

                        Tilbakestill
                      </BButton>
                    </BFormGroup>
                  </div>
                </BCol>
              </BRow>
            </BCard>

            <BRow class="my-4">
              <BCol
                md="6"
              >
                <template v-if="pdfUrl">
                  <div class="d-flex">
                    <BButton
                      size="lg"
                      type="submit"
                      variant="primary"
                      :disabled="isSubmitting"
                      class="flex-grow-0"
                    >
                      <span v-if="isSubmitting">
                        <BSpinner
                          variant="light"
                          small
                        />
                      </span>
                      <span v-else>
                        <FontAwesomeIcon :icon="['fas', 'file-plus']" />
                      </span>
                    </BButton>
                    <BButton
                      size="lg"
                      block
                      variant="success"
                      class="ml-4 flex-grow-1"
                      :href="pdfUrl"
                      target="_blank"
                    >
                      <FontAwesomeIcon :icon="['fas', 'download']" />
                      Last ned
                    </BButton>
                  </div>
                </template>
                <BButton
                  v-else
                  size="lg"
                  block
                  type="submit"
                  variant="primary"
                  :disabled="isSubmitting"
                >
                  <span v-if="isSubmitting">
                    <BSpinner
                      variant="light"
                      small
                    />
                    Genererer dokument…
                  </span>
                  <span v-else>
                    <FontAwesomeIcon :icon="['fas', 'file-plus']" />
                    Generer dokument
                  </span>
                </BButton>
              </BCol>

              <BCol md="6">
                <BButton
                  size="lg"
                  block
                  variant="secondary"
                  :disabled="isSaving"
                  @click="saveFormData"
                >
                  <span v-if="isSaving">
                    <BSpinner
                      variant="light"
                      small
                    />
                    Lagrer skjema…
                  </span>
                  <span v-else>
                    <FontAwesomeIcon :icon="['fas', 'save']" />
                    Lagre skjema
                  </span>
                </BButton>
              </BCol>
            </BRow>
          </BForm>
        </BCol>
      </BRow>
    </BContainer>
  </div>
</template>

<script>
import DateRangePicker from '@/shared/components/vue2-daterange-picker-dayjs/components/DateRangePicker'
import Dayjs from 'dayjs'
import Axios from 'axios'
import { dateFormat } from '@/shared/utils/dateRangePickerLocale'
import SignatureField from '@/admin/components/SignatureField'

export default {
  components: {
    SignatureField,
    DateRangePicker,
  },

  props: {
    apartment: {
      type: Object,
      required: true,
    },
    owners: {
      type: Array,
      required: true,
    },
    loginKey: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    form: {
      inspectionDate: new Date(),
      presentForCustomer: [],
      presentForSeller: {
        company: null,
        representative: null,
      },
      presentForEntrepreneur: {
        company: null,
        representative: null,
      },
      sign: {
        location: null,
        date: new Date(),
        customerSignatures: [],
        entrepreneurSignature: null,
        sellerSignature: null,
      },
    },
    isSubmitting: false,
    isSaving: false,
    dateRangePickerLocale: dateFormat,
    pdfUrl: null,
  }),

  computed: {
    pdfFileName () {
      return `protokoll_ettarsbefaring_${this.apartment.id}.pdf`
    },
  },

  watch: {
    'form.presentForCustomer': {
      handler (customers) {
        if (!customers) {
          return
        }

        // Removes customer signatures where the name is no longer present in presentForCustomer
        this.form.sign.customerSignatures = this.form.sign.customerSignatures
          .filter((customerSignature) => {
            customers.some((customer) => (customerSignature.name === customer.name))
          })

        customers
          .filter((customer) => (
            this.form.sign.customerSignatures.every((customerSignature) => (customerSignature.name !== customer.name))
          ))
          .forEach((customer) => {
            this.form.sign.customerSignatures.push({
              name: customer.name,
              signature: null,
            })
          })
      },
      immediate: true,
      deep: true,
    },
  },

  mounted () {
    this.prepopulateForm()
  },

  methods: {
    prepopulateForm () {
      this.getFormData()

      if (!this.form.presentForCustomer.length && this.owners.length) {
        this.owners.forEach(owner => {
          this.form.presentForCustomer.push({
            name: `${owner.firstname} ${owner.surname}`,
            contactInformation: owner.mail,
          })
        })
      }

      if (this.form.sign.location === null && typeof this.apartment.address === 'string') {
        let address = this.apartment.address

        if (typeof this.apartment.postal === 'string') {
          address += `, ${this.apartment.postal}`

          if (typeof this.apartment.city === 'string') {
            address += ` ${this.apartment.city}`
          }
        }

        this.$set(this.form.sign, 'location', address)
      }
    },

    formatDate (data) {
      return Dayjs(data).format('DD. MMMM YYYY')
    },

    submitForm () {
      this.isSubmitting = true

      Axios.post(`/api/apartments/oneYearProtocol/${this.apartment.id}`, this.form).then(response => {
        this.pdfUrl = `/api/apartments/oneYearProtocol/${this.apartment.id}`

        this.isSubmitting = false
      }).catch(error => {
        this.$bvToast.toast('Kunne ikke generere dokument.', {
          variant: 'danger',
          title: 'Noe gikk galt',
        })

        console.error(error)

        this.isSubmitting = false
      })
    },

    saveFormData () {
      this.isSaving = true

      Axios.post(`/api/apartments/oneYearProtocol/${this.apartment.id}/formData`, this.form).then(response => {
        this.$bvToast.toast('Skjemaet ble lagret.', {
          variant: 'success',
          title: 'Skjema lagret',
        })

        this.isSaving = false
      }).catch(error => {
        this.$bvToast.toast('Kunne ikke lagre skjema.', {
          variant: 'danger',
          title: 'Noe gikk galt',
        })

        console.error(error)

        this.isSaving = false
      })
    },

    getFormData () {
      const data = this.apartment.oneYearFormData

      if (data !== null) {
        if (Object.prototype.hasOwnProperty.call(data, 'inspectionDate')) {
          const date = Dayjs(data.inspectionDate)

          if (date.isValid()) {
            data.inspectionDate = date.toDate()
          }
        }

        if (Object.prototype.hasOwnProperty.call(data, 'presentForEntrepreneur')) {
          this.form.presentForEntrepreneur = Object.assign({}, data.presentForEntrepreneur)
        }

        if (Object.prototype.hasOwnProperty.call(data, 'presentForSeller')) {
          this.form.presentForSeller = Object.assign({}, data.presentForSeller)
        }

        if (Object.prototype.hasOwnProperty.call(data, 'sign')) {
          if (Object.prototype.hasOwnProperty.call(data.sign, 'date')) {
            const date = Dayjs(data.sign.date)

            if (date.isValid()) {
              data.sign.date = date.toDate()
            }
          }

          this.form.location = data.sign.location
        }
      }
    },

    autoExpandTextareaRows (value) {
      const min = 2

      if (typeof value !== 'string') {
        return min
      }

      const rows = value.split('\n')

      return rows.length > min ? rows.length : min
    },

    addPresentCustomer () {
      this.form.presentForCustomer.push({
        name: '',
        contactInformation: '',
      })
    },

    deletePresentCustomer (key) {
      this.$delete(this.form.presentForCustomer, key)
    },
  },
}
</script>
