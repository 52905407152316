<template>
  <div>
    <IssueCustomerCommunicationNewMessage :issue="issue" />
    <IssueMessages
      :issue="issue"
      type="customer"
    />
  </div>
</template>

<script>
import IssueCustomerCommunicationNewMessage from '../components/IssueCustomerCommunicationNewMessage'
import IssueMessages from '../components/IssueMessages'

export default {
  components: {
    IssueCustomerCommunicationNewMessage,
    IssueMessages,
  },

  props: {
    issue: {
      type: Object,
      required: true,
    },
  },
}
</script>
