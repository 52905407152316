import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../config/vuex/store'

import IssueMasterView from '../views/IssueMasterView'
import IssueDetailsView from '../views/IssueDetailsView'
import IssueHistoryView from '../views/IssueHistoryView'
import IssueDeadlinesView from '../views/IssueDeadlinesView'
import IssueCustomerCommunicationView from '../views/IssueCustomerCommunicationView'
import IssueProviderCommunicationView from '../views/IssueProviderCommunicationView'
import IssueInternalNotesView from '../views/IssueInternalNotesView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:id',
    component: IssueMasterView,
    props: true,
    children: [
      {
        path: '/',
        name: 'IssueDetailsView',
        component: IssueDetailsView,
        props: true,
      },
      {
        path: 'history',
        name: 'IssueHistoryView',
        component: IssueHistoryView,
        props: true,
      },
      {
        path: 'deadlines',
        name: 'IssueDeadlinesView',
        component: IssueDeadlinesView,
        props: true,
      },
      {
        path: 'communication/customer',
        name: 'IssueCustomerCommunicationView',
        component: IssueCustomerCommunicationView,
        props: true,
      },
      {
        path: 'communication/provider',
        name: 'IssueProviderCommunicationView',
        component: IssueProviderCommunicationView,
        props: true,
      },
      {
        path: 'notes',
        name: 'IssueInternalNotesView',
        component: IssueInternalNotesView,
        props: true,
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/issues',
  routes,
})

const issuePageMountElement = document.querySelector('.IssuePage')

if (issuePageMountElement) {
  new Vue({
    name: 'IssuePageRoot',

    store,
    router,
  }).$mount(issuePageMountElement)
}
