<template>
  <BModal
    ref="create-deadline-modal"
    v-bind="[$props, $attrs]"
    :title="`Opprett frist på sak #${issueId}`"
  >
    <BForm @submit.prevent="submitDeadline(hideModal)">
      <BFormGroup
        :id="`input-group-description-${$_uid}`"
        label="Beskrivelse"
        :label-for="`input-description-${$_uid}`"
      >
        <BFormTextarea
          :id="`input-description-${$_uid}`"
          v-model="form.description"
          required
          :disabled="isSubmitting"
        />
      </BFormGroup>

      <BFormGroup
        :id="`input-group-responsibleUser-${$_uid}`"
        label="Ansvarlig"
        :label-for="`input-responsibleUser-${$_uid}`"
      >
        <BFormSelect
          :id="`input-responsibleUser-${$_uid}`"
          v-model="form.responsibleUser"
          :options="responsibleUserOptions"
          required
          :disabled="loadingSuperUsers || isSubmitting"
        />
      </BFormGroup>

      <BFormGroup
        :id="`input-group-dateAndTime-${$_uid}`"
        label="Dato & tid"
        :label-for="`input-dateAndTime-${$_uid}`"
      >
        <DateRangePicker
          ref="datePicker"
          v-model="form.dateAndTime"
          single-date-picker
          time-picker
          show-dropdowns
          :ranges="null"
          :locale-data="dateRangePickerLocale"
        >
          <template v-slot:input="data">
            <BInputGroup>
              <template v-slot:prepend>
                <BInputGroupText>
                  <FontAwesomeIcon :icon="['fas', 'calendar-alt']" />
                </BInputGroupText>
              </template>

              <BFormInput
                :id="`input-dateAndTime-${$_uid}`"
                :value="formatDate(data.startDate)"
                :disabled="isSubmitting"
              />
            </BInputGroup>
          </template>
        </DateRangePicker>
      </BFormGroup>
    </BForm>

    <template v-slot:modal-footer="{ ok, cancel }">
      <BButton
        variant="secondary"
        :disabled="isSubmitting"
        @click="cancel()"
      >
        Avbryt
      </BButton>

      <BButton
        v-if="isSubmitting"
        variant="primary"
        disabled
      >
        <BSpinner
          variant="light"
          small
        />
      </BButton>

      <BButton
        v-else
        variant="primary"
        @click="submitDeadline(ok)"
      >
        Opprett frist
      </BButton>
    </template>
  </BModal>
</template>

<script>
import DateRangePicker from '@/shared/components/vue2-daterange-picker-dayjs'
import Dayjs from 'dayjs'
import Axios from 'axios'
import EventBus from '@/shared/config/eventBus'
import { dateAndTimeFormat } from '@/shared/utils/dateRangePickerLocale'

export default {
  components: {
    DateRangePicker,
  },

  props: {
    issueId: {
      type: [Number, String],
      required: true,
    },
  },

  data: () => ({
    defaultResponsibleUser: 221,
    form: {
      description: null,
      responsibleUser: null,
      dateAndTime: new Date(),
    },
    superUsers: [],
    loadingSuperUsers: true,
    dateRangePickerLocale: dateAndTimeFormat,
    isSubmitting: false,
  }),

  computed: {
    responsibleUserOptions () {
      if (!this.superUsers.length) {
        return [
          {
            value: null,
            text: 'Laster superbrukere…',
          },
        ]
      }

      return this.superUsers.map(user => {
        return {
          value: parseInt(user.id),
          text: user.firstname + ' ' + user.surname,
        }
      })
    },
  },

  mounted () {
    this.getSuperUsers()
  },

  methods: {
    async getSuperUsers () {
      await this.$store.dispatch('superUsers/getAll').then(superUsers => {
        this.superUsers = superUsers
        this.loadingSuperUsers = false
        this.form.responsibleUser = this.defaultResponsibleUser
      })
    },

    formatDate (data) {
      return Dayjs(data).format('DD-MM-YYYY HH:mm')
    },

    clearFormData () {
      this.form.description = null
      this.form.responsibleUser = this.defaultResponsibleUser
      this.form.dateAndTime = new Date()
    },

    hideModal () {
      this.$refs['create-deadline-modal'].hide()
    },

    submitDeadline (callback) {
      this.isSubmitting = true
      this.form.issue = this.issueId

      Axios.post('/api/deadlines/', this.form).then(response => {
        this.$bvToast.toast('Fristen ble opprettet.', {
          variant: 'success',
          title: 'Frist opprettet',
        })

        EventBus.$emit('deadlineCreated', response)
        this.isSubmitting = false
        this.clearFormData()
        callback()
      }).catch(error => {
        this.isSubmitting = false

        this.$bvToast.toast('Kunne ikke opprette deadline.', {
          variant: 'danger',
          title: 'Noe gikk galt',
        })

        console.error(error)
      })
    },
  },
}
</script>
