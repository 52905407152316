import Vue from 'vue'

import store from '../config/vuex/store'

import ReportsView from '../views/ReportsView'

Vue.component('reports-view', ReportsView)

const reportsPageMountElement = document.querySelector('.ReportsPage')

if (reportsPageMountElement) {
  new Vue({
    name: 'ReportsPageRoot',

    store,
  }).$mount(reportsPageMountElement)
}
