<template>
  <div>
    <BTable
      striped
      responsive
      :fields="fields"
      :items="items"
    >
      <template v-slot:cell(id)="data">
        <a :href="`/issues/${data.value}/`">{{ data.value }}</a>
      </template>

      <template v-slot:cell(project)="data">
        <a :href="`/projects/${data.value.id}/`">{{ data.value.name }}</a>
      </template>

      <template v-slot:cell(apartment)="data">
        <a :href="`/apartments/${data.value.id}/`">{{ data.value.aptNumber }}</a>
      </template>
    </BTable>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    fields: [
      { key: 'id', label: '#' },
      { key: 'date', label: 'Dato' },
      { key: 'project', label: 'Prosjekt' },
      { key: 'apartment', label: 'Leilighet' },
      { key: 'customerName', label: 'Navn' },
    ],
  }),
}
</script>
