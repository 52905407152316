<template>
  <BRow class="IssueFilters">
    <BCol
      md="6"
      lg="8"
    >
      <BFormGroup
        :id="`input-group-dateRange-${$_uid}`"
        :label-for="`input-dateRange-${$_uid}`"
      >
        <template #label>
          Tidsperiode

          <BFormCheckbox
            v-model="enableDateFilter"
            class="d-inline-block ml-2"
            switch
          />
        </template>

        <DateRangePicker
          ref="datePicker"
          v-model="dateRange"
          show-dropdowns
          :ranges="null"
          :locale-data="dateRangePickerLocale"
          :disabled="!enableDateFilter"
        >
          <template #input="data">
            <BInputGroup>
              <template v-slot:prepend>
                <BInputGroupText>
                  <FontAwesomeIcon :icon="['fas', 'calendar-alt']" />
                </BInputGroupText>
              </template>

              <BFormInput
                :id="`input-dateRange-${$_uid}`"
                :value="`${formatDate(data.startDate)} → ${formatDate(data.endDate)}`"
                :disabled="!enableDateFilter"
              />
            </BInputGroup>
          </template>
        </DateRangePicker>
      </BFormGroup>
    </BCol>

    <BCol
      md="6"
      lg="4"
    >
      <BFormGroup
        :id="`input-group-projectStages-${$_uid}`"
        label="Prosjekt"
        :label-for="`input-projectStages-${$_uid}`"
      >
        <VueMultiselect
          v-model="projectStages"
          :options="availableProjectStages"
          label="name"
          track-by="id"
          multiple
          :placeholder="isLoadingProjectStages ? 'Laster prosjekt…' : 'Velg prosjekt'"
          select-label="Trykk enter for å velge"
          select-group-label="Trykk enter for å velge gruppe"
          selected-label="Valgt"
          deselect-label="Trykk enter for å fjerne"
          deselect-group-label="Trykk enter for å fjerne grupp"
          :limit-text="count => `og ${count} til`"
          :close-on-select="false"
          :disabled="isLoadingProjectStages"
        />
      </BFormGroup>
    </BCol>

    <BCol
      md="6"
      lg="4"
    >
      <BFormGroup
        :id="`input-group-apartments-${$_uid}`"
        label="Leilighet"
        :label-for="`input-apartments-${$_uid}`"
      >
        <VueMultiselect
          v-model="apartments"
          :options="filteredApartments"
          label="aptNumber"
          track-by="id"
          multiple
          :placeholder="isLoadingApartments ? 'Laster leiligheter…' : 'Velg leilighet'"
          select-label="Trykk enter for å velge"
          select-group-label="Trykk enter for å velge gruppe"
          selected-label="Valgt"
          deselect-label="Trykk enter for å fjerne"
          deselect-group-label="Trykk enter for å fjerne gruppe"
          :limit-text="count => `og ${count} til`"
          :close-on-select="false"
          :disabled="isLoadingApartments"
        >
          <template #noOptions>
            Ingen leiligheter for valgte prosjekt
          </template>
        </VueMultiselect>
      </BFormGroup>
    </BCol>

    <BCol
      md="6"
      lg="4"
    >
      <BFormGroup
        :id="`input-group-issueCategories-${$_uid}`"
        label="Kategori"
        :label-for="`input-issueCategories-${$_uid}`"
      >
        <VueMultiselect
          v-model="categories"
          :options="availableCategories"
          label="name"
          track-by="id"
          multiple
          :placeholder="isLoadingCategories ? 'Laster kategorier…' : 'Velg kategori(er)'"
          select-label="Trykk enter for å velge"
          select-group-label="Trykk enter for å velge gruppe"
          selected-label="Valgt"
          deselect-label="Trykk enter for å fjerne"
          deselect-group-label="Trykk enter for å fjerne gruppe"
          :limit-text="count => `og ${count} til`"
          :close-on-select="false"
          :disabled="isLoadingCategories"
        />
      </BFormGroup>
    </BCol>

    <BCol
      md="6"
      lg="4"
    >
      <BFormGroup
        :id="`input-group-issueFields-${$_uid}`"
        label="Fagområde"
        :label-for="`input-issueFields-${$_uid}`"
      >
        <VueMultiselect
          v-model="fields"
          :options="capitalizedFields"
          group-values="children"
          group-label="name"
          :group-select="false"
          label="name"
          track-by="name"
          multiple
          :placeholder="isLoadingFields ? 'Laster fagområder…' : 'Velg fagområde(r)'"
          select-label="Trykk enter for å velge"
          select-group-label="Trykk enter for å velge gruppe"
          selected-label="Valgt"
          deselect-label="Trykk enter for å fjerne"
          deselect-group-label="Trykk enter for å fjerne gruppe"
          :limit-text="count => `og ${count} til`"
          :close-on-select="false"
          :disabled="isLoadingFields"
        />
      </BFormGroup>
    </BCol>

    <BCol
      md="6"
      lg="4"
    >
      <BFormGroup
        :id="`input-group-issueTypes-${$_uid}`"
        label="Sakstype"
        :label-for="`input-issueTypes-${$_uid}`"
      >
        <VueMultiselect
          v-model="types"
          :options="availableTypes"
          label="name"
          track-by="key"
          multiple
          :placeholder="isLoadingTypes ? 'Laster sakstyper…' : 'Velg sakstype(r)'"
          select-label="Trykk enter for å velge"
          select-group-label="Trykk enter for å velge gruppe"
          selected-label="Valgt"
          deselect-label="Trykk enter for å fjerne"
          deselect-group-label="Trykk enter for å fjerne gruppe"
          :limit-text="count => `og ${count} til`"
          :close-on-select="false"
          :disabled="isLoadingTypes"
        />
      </BFormGroup>
    </BCol>

    <BCol
      md="6"
      lg="4"
    >
      <BFormGroup
        :id="`input-group-issueStatuses-${$_uid}`"
        label="Status"
        :label-for="`input-issueStatuses-${$_uid}`"
      >
        <VueMultiselect
          v-model="statuses"
          :options="availableStatuses"
          label="name"
          track-by="key"
          multiple
          :placeholder="isLoadingStatuses ? 'Laster statuser…' : 'Velg status(er)'"
          select-label="Trykk enter for å velge"
          select-group-label="Trykk enter for å velge gruppe"
          selected-label="Valgt"
          deselect-label="Trykk enter for å fjerne"
          deselect-group-label="Trykk enter for å fjerne gruppe"
          :limit-text="count => `og ${count} til`"
          :close-on-select="false"
          :disabled="isLoadingStatuses"
        />
      </BFormGroup>
    </BCol>

    <BCol
      md="6"
      lg="4"
    >
      <BFormGroup
        :id="`input-group-issueResponsiblePersons-${$_uid}`"
        label="Ansvarlig"
        :label-for="`input-issueResponsiblePersons-${$_uid}`"
      >
        <VueMultiselect
          v-model="responsiblePersons"
          :options="filteredResponsiblePersons"
          label="name"
          :custom-label="getResponsiblePersonsCustomLabel"
          track-by="id"
          multiple
          :placeholder="isLoadingResponsiblePersons ? 'Laster ansvarlige…' : 'Velg ansvarlig(e)'"
          select-label="Trykk enter for å velge"
          select-group-label="Trykk enter for å velge gruppe"
          selected-label="Valgt"
          deselect-label="Trykk enter for å fjerne"
          deselect-group-label="Trykk enter for å fjerne gruppe"
          :limit-text="count => `og ${count} til`"
          :close-on-select="false"
          :disabled="isLoadingResponsiblePersons"
        >
          <template #noOptions>
            Ingen ansvarlige for valgt prosjekt
          </template>
        </VueMultiselect>
      </BFormGroup>
    </BCol>
  </BRow>
</template>

<script>
import Dayjs from 'dayjs'
import { dateFormat } from '@/shared/utils/dateRangePickerLocale'
import DateRangePicker from '@/shared/components/vue2-daterange-picker-dayjs/components/DateRangePicker'
import VueMultiselect from 'vue-multiselect'

const capitalize = (string) => {
  if (typeof string !== 'string') return ''

  return string.charAt(0).toUpperCase() + string.slice(1)
}

export default {
  name: 'IssueFilters',

  components: {
    DateRangePicker,
    VueMultiselect,
  },

  props: {
    filters: {
      type: Object,
      default: Object,
    },
  },

  data: () => ({
    enableDateFilter: false,
    dateRange: {
      startDate: Dayjs().subtract(1, 'month').toDate(),
      endDate: new Date(),
    },
    statuses: [],
    projectStages: [],
    apartments: [],
    fields: [],
    types: [],
    responsiblePersons: [],
    categories: [],

    dateRangePickerLocale: dateFormat,

    isLoadingStatuses: true,
    statusesPromise: null,
    availableStatuses: [],

    isLoadingProjectStages: true,
    projectStagesPromise: null,
    availableProjectStages: [],

    isLoadingApartments: true,
    apartmentsPromise: null,
    availableApartments: [],

    isLoadingFields: true,
    fieldsPromise: null,
    availableFields: [],

    isLoadingTypes: true,
    typesPromise: null,
    availableTypes: [],

    isLoadingResponsiblePersons: true,
    responsiblePersonsPromise: null,
    availableResponsiblePersons: [],

    isLoadingCategories: true,
    categoriesPromise: null,
    availableCategories: [],

    loading: true,
  }),

  computed: {
    // Filters apartments based on selected projectStage
    filteredApartments () {
      if (this.projectStages.length === 0) {
        return this.availableApartments
      }

      return this.availableApartments.filter((apartment) => (
        this.projectStages.some((projectStage) => (
          apartment.projectStage === projectStage.id
        ))
      ))
    },

    // FIlters responsible persons based on selected projectStage
    filteredResponsiblePersons () {
      if (this.projectStages.length === 0) {
        return this.availableResponsiblePersons
      }

      return this.availableResponsiblePersons.filter((responsiblePerson) => (
        this.projectStages.some((projectStage) => (
          projectStage.contactPersonIds.includes(responsiblePerson.id)
        ))
      ))
    },

    capitalizedFields () {
      return this.availableFields.map((field) => ({
        ...field,
        children: field.children.map((child) => ({
          ...child,
          name: capitalize(child.name),
        })),
      }))
    },

    tableFilters () {
      const filters = {}

      if (this.enableDateFilter) {
        const start = Dayjs(this.dateRange.startDate).set('hour', 0).set('minute', 0).set('second', 0)
        const end = Dayjs(this.dateRange.endDate).set('hour', 23).set('minute', 59).set('second', 59)
        filters.dateRange = start.unix() + '-' + end.unix()
      }

      if (this.projectStages.length > 0) {
        filters.projectStages = this.projectStages.map((projectStage) => (projectStage.id)).join(',')
      }

      if (this.apartments.length > 0) {
        filters.apartments = this.apartments.map((apartment) => (apartment.id)).join(',')
      }

      if (this.categories.length > 0) {
        filters.categories = this.categories.map((category) => (category.id)).join(',')
      }

      if (this.fields.length > 0) {
        filters.fields = this.fields.map((field) => (field.id)).join(',')
      }

      if (this.types.length > 0) {
        filters.types = this.types.map((type) => (type.key)).join(',')
      }

      if (this.statuses.length > 0) {
        filters.statuses = this.statuses.map((status) => (status.key)).join(',')
      }

      if (this.responsiblePersons.length > 0) {
        filters.responsiblePersons = this.responsiblePersons.map((responsiblePerson) => (responsiblePerson.id)).join(',')
      }

      return filters
    },
  },

  watch: {
    'filters.dateRange': {
      handler (dateRange) {
        if (!dateRange) {
          this.dateRange.startDate = Dayjs().subtract(1, 'month').toDate()
          this.dateRange.endDate = new Date()

          return
        }

        const [start, end] = dateRange.split('-')
          .map(Number)
          .map((timestamp) => (Dayjs.unix(timestamp).toDate()))

        this.dateRange.startDate = start
        this.dateRange.endDate = end
      },
      immediate: true,
    },

    'filters.projectStages': {
      async handler (projectStages) {
        if (this.isLoadingProjectStages) {
          await this.projectStagesPromise
        }

        if (!projectStages) {
          this.projectStages = []

          return
        }

        this.projectStages = projectStages.split(',')
          .map(Number)
          .map((projectStageId) => (
            this.availableProjectStages.find((projectStage) => (projectStage.id === projectStageId))
          ))
      },
      immediate: true,
    },

    'filters.apartments': {
      async handler (apartments) {
        if (this.isLoadingApartments) {
          await this.apartmentsPromise
        }

        if (!apartments) {
          this.apartments = []

          return
        }

        this.apartments = apartments
          .split(',')
          .map(Number)
          .map((apartmentId) => (
            this.availableApartments.find((available) => (available.id === apartmentId))
          ))
      },
      immediate: true,
    },

    'filters.categories': {
      async handler (categories) {
        if (this.isLoadingCategories) {
          await this.categoriesPromise
        }

        if (!categories) {
          this.categories = []

          return
        }

        this.categories = categories
          .split(',')
          .map(Number)
          .map((categoryId) => (
            this.availableCategories.find((category) => (category.id === categoryId))
          ))
      },
      immediate: true,
    },

    'filters.fields': {
      async handler (fields) {
        if (this.isLoadingFields) {
          await this.fieldsPromise
        }

        if (!fields) {
          this.fields = []

          return
        }

        this.fields = fields
          .split(',')
          .map(Number)
          .map((fieldId) => (
            this.availableFields
              .flatMap((fieldGroup) => (fieldGroup.children))
              .find((field) => (field.id === fieldId))
          ))
      },
      immediate: true,
    },

    'filters.types': {
      async handler (types) {
        if (this.isLoadingTypes) {
          await this.typesPromise
        }

        if (!types) {
          this.types = []

          return
        }

        this.types = types
          .split(',')
          .map((typeKey) => (
            this.availableTypes.find((type) => (type.key === typeKey))
          ))
      },
      immediate: true,
    },

    'filters.statuses': {
      async handler (statuses) {
        if (this.isLoadingStatuses) {
          await this.statusesPromise
        }

        if (!statuses) {
          this.statuses = []

          return
        }

        this.statuses = statuses
          .split(',')
          .map((statusKey) => (
            this.availableStatuses.find((status) => (status.key === statusKey))
          ))
      },
      immediate: true,
    },

    'filters.responsiblePersons': {
      async handler (responsiblePersons) {
        if (this.isLoadingResponsiblePersons) {
          await this.responsiblePersonsPromise
        }

        if (!responsiblePersons) {
          this.responsiblePersons = []

          return
        }

        this.responsiblePersons = responsiblePersons
          .split(',')
          .map(Number)
          .map((responsiblePersonId) => (
            this.availableResponsiblePersons
              .find((responsiblePerson) => (responsiblePerson.id === responsiblePersonId))
          ))
      },
      immediate: true,
    },

    // Remove invalid selected apartments
    projectStages (projectStages) {
      if (projectStages.length === 0) {
        return
      }

      this.apartments = this.apartments.filter((apartment) => (
        this.projectStages.some((projectStage) => (
          apartment.projectStage === projectStage.id
        ))
      ))
    },

    tableFilters (tableFilters) {
      if (this.loading) {
        return
      }

      this.$emit('update:filters', tableFilters)
    },
  },

  async created () {
    this.statusesPromise = this.getStatuses()
    this.projectStagesPromise = this.getProjectStages()
    this.apartmentsPromise = this.getApartments()
    this.fieldsPromise = this.getFields()
    this.typesPromise = this.getTypes()
    this.responsiblePersonsPromise = this.getResponsiblePersons()
    this.categoriesPromise = this.getCategories()

    await this.statusesPromise
    await this.projectStagesPromise
    await this.apartmentsPromise
    await this.fieldsPromise
    await this.typesPromise
    await this.responsiblePersonsPromise
    await this.categoriesPromise

    this.loading = false
  },

  methods: {
    formatDate (data) {
      return Dayjs(data).format('DD.MM.YYYY')
    },

    async getStatuses () {
      await this.$store.dispatch('issues/getStatuses')

      this.availableStatuses = this.$store.state.issues.statuses
      this.isLoadingStatuses = false
    },

    async getProjectStages () {
      await this.$store.dispatch('apartments/getProjectStages')

      this.availableProjectStages = this.$store.state.apartments.projectStages
      this.isLoadingProjectStages = false
    },

    async getApartments () {
      await this.$store.dispatch('apartments/getApartments')

      this.availableApartments = this.$store.state.apartments.apartments
      this.isLoadingApartments = false
    },

    async getFields () {
      await this.$store.dispatch('issues/getFields')

      this.availableFields = this.$store.state.issues.fields
      this.isLoadingFields = false
    },

    async getTypes () {
      await this.$store.dispatch('issues/getTypes')

      this.availableTypes = this.$store.state.issues.types
      this.isLoadingTypes = false
    },

    async getResponsiblePersons () {
      await this.$store.dispatch('issues/getResponsiblePersons')

      this.availableResponsiblePersons = this.$store.state.issues.responsiblePersons
      this.isLoadingResponsiblePersons = false
    },

    async getCategories () {
      await this.$store.dispatch('issues/getCategories')

      this.availableCategories = this.$store.state.issues.categories
      this.isLoadingCategories = false
    },

    getResponsiblePersonsCustomLabel (responsiblePerson) {
      return `${responsiblePerson.name} - ${responsiblePerson.company}`
    },
  },
}
</script>
