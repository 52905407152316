<template>
  <BModal
    ref="create-contact-person-modal"
    v-bind="[$props, $attrs]"
    :title="`Opprett ny kontaktperson`"
  >
    <BForm
      ref="form"
      @submit.prevent="submitForm(hideModal)"
      @keydown.enter="submitForm(hideModal)"
    >
      <BFormGroup
        :id="`input-group-name-${$_uid}`"
        label="Navn"
        :label-for="`input-name-${$_uid}`"
      >
        <BFormInput
          :id="`input-name-${$_uid}`"
          v-model="form.name"
          type="text"
          required
          :disabled="isSubmitting"
        />
      </BFormGroup>

      <BFormGroup
        :id="`input-group-company-${$_uid}`"
        label="Selskap"
        :label-for="`input-company-${$_uid}`"
      >
        <BFormInput
          :id="`input-company-${$_uid}`"
          v-model="form.company"
          type="text"
          required
          :disabled="isSubmitting"
        />
      </BFormGroup>

      <BFormGroup
        :id="`input-group-email-${$_uid}`"
        label="E-postadresse"
        :label-for="`input-email-${$_uid}`"
      >
        <BFormInput
          :id="`input-email-${$_uid}`"
          v-model="form.email"
          type="email"
          required
          :disabled="isSubmitting"
        />
      </BFormGroup>

      <BFormGroup
        :id="`input-group-phone-number-${$_uid}`"
        label="Telefonnummer"
        :label-for="`input-phone-number-${$_uid}`"
      >
        <BFormInput
          :id="`input-phone-number-${$_uid}`"
          v-model="form.phoneNumber"
          type="tel"
          required
          :disabled="isSubmitting"
        />
      </BFormGroup>
    </BForm>

    <template v-slot:modal-footer="{ ok, cancel }">
      <BButton
        variant="secondary"
        :disabled="isSubmitting"
        @click="cancel()"
      >
        Avbryt
      </BButton>

      <BButton
        v-if="isSubmitting"
        variant="primary"
        disabled
      >
        <BSpinner
          variant="light"
          small
        />
      </BButton>

      <BButton
        v-else
        variant="primary"
        @click="submitForm(ok)"
      >
        Legg til
      </BButton>
    </template>
  </BModal>
</template>

<script>
import Axios from 'axios'

export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    form: {
      name: null,
      company: null,
      email: null,
      phoneNumber: null,
    },
    isSubmitting: false,
  }),

  mounted () {
  },

  methods: {
    clearFormData () {
      this.form.name = null
      this.form.email = null
    },

    hideModal () {
      this.$refs['create-contact-person-modal'].hide()
    },

    submitForm (callback) {
      if (this.$refs.form.reportValidity()) {
        this.isSubmitting = true

        Axios.post(`/api/projects/${this.project.id}/contactPersons`, this.form).then(response => {
          this.$bvToast.toast('Kontaktpersonen ble opprettet og lagt til.', {
            variant: 'success',
            title: 'Kontaktperson opprettet',
          })

          this.$emit('contactPersonCreated', response)
          this.isSubmitting = false
          this.clearFormData()
          callback()
        }).catch(error => {
          this.isSubmitting = false

          this.$bvToast.toast('Kunne ikke opprette kontaktperson.', {
            variant: 'danger',
            title: 'Noe gikk galt',
          })

          console.error(error)
        })
      }
    },
  },
}
</script>
