<template>
  <IssueHistoryTimeline :items="issue.history" />
</template>

<script>
import IssueHistoryTimeline from '../components/IssueHistoryTimeline'

export default {
  components: {
    IssueHistoryTimeline,
  },

  props: {
    issue: {
      type: Object,
      required: true,
    },
  },
}
</script>
