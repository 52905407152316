<template>
  <BContainer fluid>
    <BRow>
      <BCol>
        <BCard class="DashboardCard">
          <BForm @submit.prevent="generateReport">
            <IssueFilters :filters.sync="filters" />

            <BRow>
              <BCol>
                <BButton
                  variant="primary"
                  type="submit"
                >
                  <FontAwesomeIcon :icon="['fas', 'sync']" />
                  Generer rapport
                </BButton>
              </BCol>
            </BRow>
          </BForm>
        </BCard>

        <BCard class="DashboardCard">
          <IssuesTable
            ref="issuesTable"
            table-id="issues-table-reports"
            :filters.sync="filters"
            sync-with-url
            enable-exports
            :enable-create-report-button="false"
          />
        </BCard>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import IssuesTable from '../components/IssuesTable'
import IssueFilters from '@/admin/components/IssueFilters'

export default {
  components: {
    IssueFilters,
    IssuesTable,
  },

  data: () => ({
    filters: {},
  }),

  methods: {
    generateReport () {
      if (this.$refs.issuesTable) {
        this.$refs.issuesTable.refresh()
      }
    },
  },
}
</script>
