<template>
  <BBadge :variant="status">
    <FontAwesomeIcon
      class="mr-1"
      :icon="['fas', mapStatusToIcon(status)]"
    />
    {{ days }}d
  </BBadge>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
    days: {
      type: [String, Number],
      required: true,
    },
  },

  methods: {
    mapStatusToIcon (status) {
      let icon = 'check'
      icon = (status === 'danger') ? 'times' : icon
      icon = (status === 'warning') ? 'exclamation-circle' : icon

      return icon
    },
  },
}
</script>
