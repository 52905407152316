import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../config/vuex/store'

import ProjectMasterView from '../views/ProjectMasterView'
import ProjectContactPersonsView from '../views/ProjectContactPersonsView'
import ProjectApartmentsView from '../views/ProjectApartmentsView'
import ProjectIssuesView from '../views/ProjectIssuesView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:id',
    component: ProjectMasterView,
    props: true,
    children: [
      {
        path: '/',
        name: 'ProjectContactPersonsView',
        component: ProjectContactPersonsView,
        props: true,
      },
      {
        path: 'projects',
        name: 'ProjectApartmentsView',
        component: ProjectApartmentsView,
        props: true,
      },
      {
        path: 'issues/:status',
        name: 'ProjectIssuesView',
        component: ProjectIssuesView,
        props: true,
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/projects',
  routes,
})

const projectPageMountElement = document.querySelector('.ProjectPage')

if (projectPageMountElement) {
  new Vue({
    name: 'ProjectPageRoot',

    store,
    router,
  }).$mount(projectPageMountElement)
}
