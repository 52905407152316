import Axios from 'axios'

export const namespaced = true

export const state = {
  messages: {},
  providerRecipients: {},
  standardReplies: {},
}

export const getters = {}

export const mutations = {
  SET_ISSUE_MESSAGES (state, payload) {
    state.messages[payload.issueId] = payload.messages
  },
  SET_ISSUE_PROVIDER_RECIPIENTS (state, payload) {
    state.providerRecipients[payload.issueId] = payload.recipients
  },
  SET_TYPE_STANDARD_REPLIES (state, payload) {
    state.standardReplies[payload.type] = payload.reply
  },
  SET_MESSAGES (state, payload) {
    state.messages = payload
  },
  SET_PROVIDER_RECIPIENTS (state, payload) {
    state.providerRecipients = payload
  },
  SET_STANDARD_REPLIES (state, payload) {
    state.standardReplies = payload
  },
}

export const actions = {
  async getMessagesByIssue ({ state, commit }, issueId) {
    if (state.messages[issueId]) {
      return state.messages[issueId]
    }

    return Axios.get(`/api/messages/issue/${issueId}`).then(response => {
      commit('SET_ISSUE_MESSAGES', {
        issueId: issueId,
        messages: response.data.data,
      })

      return state.messages[issueId]
    }).catch(error => {
      console.error(error)
      return null
    })
  },
  async getProviderRecipientsByIssue ({ state, commit }, issueId) {
    if (state.providerRecipients[issueId] && state.providerRecipients[issueId].length) {
      return state.providerRecipients[issueId]
    }

    return Axios.get(`/api/issues/${issueId}/contactPersons`).then(response => {
      commit('SET_ISSUE_PROVIDER_RECIPIENTS', {
        issueId: issueId,
        recipients: response.data.data,
      })

      return state.providerRecipients[issueId]
    }).catch(error => {
      console.error(error)
      return null
    })
  },
  async getStandardReplies ({ state, commit }, type) {
    if (state.standardReplies[type] && state.standardReplies[type].length) {
      return state.standardReplies[type]
    }

    return Axios.get(`/api/standardReplies/${type}`).then(response => {
      commit('SET_TYPE_STANDARD_REPLIES', {
        type: type,
        reply: response.data.data,
      })

      return state.standardReplies[type]
    }).catch(error => {
      console.error(error)
      return []
    })
  },
  async reset ({ commit }) {
    commit('SET_MESSAGES', {})
    commit('SET_PROVIDER_RECIPIENTS', {})
    commit('SET_STANDARD_REPLIES', {})
  },
}
