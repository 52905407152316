<template>
  <div class="Page__mainView TakeoverProtocol">
    <BContainer fluid>
      <BRow>
        <BCol
          cols="12"
          md="7"
          lg="5"
          offset-md="1"
          offset-lg="2"
        >
          <h1 class="Page__mainHeading">
            <FontAwesomeIcon
              class="Page__mainHeadingIcon"
              :icon="['fas', 'handshake-alt']"
            />
            {{ apartment.aptNumber }}
            <small class="Page__mainHeadingSub Page__mainHeadingSub--block">
              Protokoll fra overtakelsesbefaring
            </small>
          </h1>
        </BCol>
        <BCol
          cols="12"
          md="3"
          lg="3"
        >
          <div class="MasterActions">
            <BButton
              class="MasterActions__button"
              variant="link"
              :to="{ name: 'ApartmentDetailsView' }"
            >
              <FontAwesomeIcon :icon="['fas', 'arrow-left']" />
              Tilbake
            </BButton>
            <BDropdown
              class="MasterActions__button"
              variant="primary"
            >
              <template #button-content>
                <FontAwesomeIcon :icon="['fas', 'plus']" />
                Ny sak
              </template>

              <BDropdownGroup header="Logg inn som">
                <BDropdownItem
                  v-for="(owner, key) in owners"
                  :key="key"
                  :href="`https://minside.vestaksen.no/loginAsCustomer/${owner.id}/${apartment.id}/?key=${loginKey}`"
                  target="_blank"
                >
                  {{ owner.firstname }} {{ owner.surname }}
                </BDropdownItem>
              </BDropdownGroup>
            </BDropdown>
          </div>
        </BCol>
      </BRow>

      <BRow>
        <BCol
          cols="12"
          md="10"
          lg="8"
          offset-md="1"
          offset-lg="2"
        >
          <BForm @submit.prevent="submitForm">
            <BCard class="FormSectionCard">
              <template #header>
                <h2 class="FormSectionCard__heading">
                  Befaring
                </h2>
              </template>

              <BFormGroup
                :id="`input-group-inspectionDate-${$_uid}`"
                class="FormSectionCard__formGroup"
                label="Overtakelsesbefaring avholdt"
                :label-for="`input-inspectionDate-${$_uid}`"
              >
                <DateRangePicker
                  ref="datePicker"
                  v-model="form.inspectionDate"
                  single-date-picker
                  show-dropdowns
                  :ranges="null"
                  :locale-data="dateRangePickerLocale"
                >
                  <template #input="data">
                    <BInputGroup>
                      <template #prepend>
                        <BInputGroupText>
                          <FontAwesomeIcon :icon="['fas', 'calendar-alt']" />
                        </BInputGroupText>
                      </template>

                      <BFormInput
                        :id="`input-inspectionDate-${$_uid}`"
                        :value="formatDate(data.startDate)"
                      />
                    </BInputGroup>
                  </template>
                </DateRangePicker>
              </BFormGroup>
            </BCard>

            <BCard class="FormSectionCard">
              <template #header>
                <h2 class="FormSectionCard__heading">
                  Tilstede
                </h2>
              </template>

              <BFormGroup
                :id="`input-group-presentForCustomer-${$_uid}`"
                class="FormSectionCard__formGroup"
                label="Tilstede for kjøper(e)"
                :label-for="`input-presentForCustomer-${$_uid}`"
                description="Skriv ett navn per linje."
              >
                <BFormTextarea
                  :id="`input-presentForCustomer-${$_uid}`"
                  v-model="form.presentForCustomer"
                  :rows="presentForCustomerTextareaRows"
                />
              </BFormGroup>

              <BFormGroup
                :id="`input-group-presentForSeller-${$_uid}`"
                class="FormSectionCard__formGroup"
                label="Tilstede for selger"
                :label-for="`input-presentForSeller-${$_uid}`"
              >
                <BFormTextarea
                  :id="`input-presentForSeller-${$_uid}`"
                  v-model="form.presentForSeller"
                  :rows="presentForSellerTextareaRows"
                />
              </BFormGroup>

              <BFormGroup
                :id="`input-group-presentForEntrepreneur-${$_uid}`"
                class="FormSectionCard__formGroup"
                label="Tilstede for entreprenør"
                :label-for="`input-presentForEntrepreneur-${$_uid}`"
              >
                <BFormTextarea
                  :id="`input-presentForEntrepreneur-${$_uid}`"
                  v-model="form.presentForEntrepreneur"
                  :rows="presentForEntrepreneurTextareaRows"
                />
              </BFormGroup>

              <BFormGroup
                :id="`input-group-protocolDistribution-${$_uid}`"
                class="FormSectionCard__formGroup"
                label="Distribusjon av protokoll"
                :label-for="`input-protocolDistribution-${$_uid}`"
                description="Skriv èn e-postadresse per linje."
              >
                <BFormTextarea
                  :id="`input-protocolDistribution-${$_uid}`"
                  v-model="form.protocolDistribution"
                  :rows="protocolDistributionTextareaRows"
                />
              </BFormGroup>
            </BCard>

            <BCard class="FormSectionCard">
              <template #header>
                <h2 class="FormSectionCard__heading">
                  Overtakelse
                </h2>
              </template>

              <BFormGroup
                class="FormSectionCard__formGroup"
                label="Følgende punkter er kontrollert"
                label-class="FormSectionCard__parentLabel"
              >
                <BFormCheckboxGroup
                  v-model="form.controlPointsChecklist"
                  class="Checklist"
                  name="controlPointsChecklist"
                  stacked
                >
                  <div
                    v-for="(option, key) in controlPointsOptions"
                    :key="key"
                    class="Checklist__item"
                  >
                    <div class="Checklist__itemMainContainer">
                      <BFormCheckbox
                        :value="option.id"
                        class="Checklist__checkbox"
                      >
                        {{ option.label }}
                      </BFormCheckbox>

                      <div class="Checklist__actions">
                        <BButton
                          v-if="controlPointsChecklistItemHasComment(option.id)"
                          v-b-tooltip.hover.left="'Fjern kommentar'"
                          class="Checklist__action Checklist__action--remove"
                          variant="link"
                          @click="removecontrolPointsChecklistComment(option.id)"
                        >
                          <FontAwesomeIcon :icon="['fas', 'comment-times']" />
                        </BButton>

                        <BButton
                          v-else
                          v-b-tooltip.hover.left="'Legg til kommentar'"
                          class="Checklist__action Checklist__action--add"
                          variant="link"
                          @click="addcontrolPointsChecklistComment(option.id)"
                        >
                          <FontAwesomeIcon :icon="['fas', 'comment-plus']" />
                        </BButton>
                      </div>
                    </div>

                    <div
                      v-if="controlPointsChecklistItemHasComment(option.id)"
                      class="Checklist__comment"
                    >
                      <hr>
                      <BFormGroup
                        :id="`input-group-controlPointsChecklistComment-${option.id}`"
                        label="Kommentar"
                        :label-for="`input-controlPointsChecklistComment-${option.id}`"
                      >
                        <BFormTextarea
                          :id="`input-controlPointsChecklistComment-${option.id}`"
                          v-model="form.controlPointsChecklistComments[`item-${option.id}`]"
                          rows="2"
                        />
                      </BFormGroup>
                    </div>
                  </div>
                </BFormCheckboxGroup>
              </BFormGroup>
            </BCard>

            <BCard class="FormSectionCard">
              <template #header>
                <h2 class="FormSectionCard__heading">
                  Diverse opplysninger
                </h2>
              </template>

              <BCard
                class="FormSectionCard FormSectionCard--subSection"
                tag="fieldset"
              >
                <legend class="FormSectionCard__parentLabel">
                  Sameiet felles bygningsforsikring
                </legend>

                <BRow>
                  <BCol md="6">
                    <BFormGroup
                      :id="`input-group-jointInsuranceCompany-${$_uid}`"
                      class="FormSectionCard__formGroup"
                      label="Forsikringsselskap"
                      :label-for="`input-jointInsuranceCompany-${$_uid}`"
                    >
                      <BFormInput
                        :id="`input-jointInsuranceCompany-${$_uid}`"
                        v-model="form.jointInsuranceCompany"
                      />
                    </BFormGroup>
                  </BCol>

                  <BCol md="6">
                    <BFormGroup
                      :id="`input-group-jointInsuranceNumber-${$_uid}`"
                      class="FormSectionCard__formGroup"
                      label="Polisenummer"
                      :label-for="`input-jointInsuranceNumber-${$_uid}`"
                    >
                      <BFormInput
                        :id="`input-jointInsuranceNumber-${$_uid}`"
                        v-model="form.jointInsuranceNumber"
                      />
                    </BFormGroup>
                  </BCol>
                </BRow>
              </BCard>

              <BCard
                class="FormSectionCard FormSectionCard--subSection"
                tag="fieldset"
              >
                <legend class="FormSectionCard__parentLabel">
                  Ferdigattest
                </legend>

                <BRow>
                  <BCol md="6">
                    <BFormCheckbox
                      v-model="form.showCertificateOfCompletionDate"
                      switch
                    >
                      Vis dato for ferdigattest
                    </BFormCheckbox>
                  </BCol>

                  <BCol md="6">
                    <BFormCheckbox
                      v-model="form.showTempUsePermitDate"
                      switch
                    >
                      Vis dato for midlertidig brukstillatelse
                    </BFormCheckbox>
                  </BCol>
                </BRow>

                <BRow class="mt-4">
                  <BCol md="6">
                    <BFormGroup
                      :id="`input-group-certificateOfCompletionDate-${$_uid}`"
                      class="FormSectionCard__formGroup"
                      label="Ferdigattest utstedes/utstedt"
                      :label-for="`input-certificateOfCompletionDate-${$_uid}`"
                    >
                      <DateRangePicker
                        ref="datePicker"
                        v-model="form.certificateOfCompletionDate"
                        single-date-picker
                        show-dropdowns
                        :ranges="null"
                        :locale-data="dateRangePickerLocale"
                        :disabled="!form.showCertificateOfCompletionDate"
                      >
                        <template #input="data">
                          <BInputGroup>
                            <template #prepend>
                              <BInputGroupText>
                                <FontAwesomeIcon :icon="['fas', 'calendar-alt']" />
                              </BInputGroupText>
                            </template>

                            <BFormInput
                              :id="`input-certificateOfCompletionDate-${$_uid}`"
                              :value="formatDate(data.startDate)"
                              :disabled="data.disabled"
                            />
                          </BInputGroup>
                        </template>
                      </DateRangePicker>
                    </BFormGroup>
                  </BCol>

                  <BCol md="6">
                    <BFormGroup
                      :id="`input-group-tempUsePermitDate-${$_uid}`"
                      class="FormSectionCard__formGroup"
                      label="Midlertidig brukstillatelse av"
                      :label-for="`input-tempUsePermitDate-${$_uid}`"
                    >
                      <DateRangePicker
                        ref="datePicker"
                        v-model="form.tempUsePermitDate"
                        single-date-picker
                        show-dropdowns
                        :ranges="null"
                        :locale-data="dateRangePickerLocale"
                        :disabled="!form.showTempUsePermitDate"
                      >
                        <template #input="data">
                          <BInputGroup>
                            <template #prepend>
                              <BInputGroupText>
                                <FontAwesomeIcon :icon="['fas', 'calendar-alt']" />
                              </BInputGroupText>
                            </template>

                            <BFormInput
                              :id="`input-tempUsePermitDate-${$_uid}`"
                              :value="formatDate(data.startDate)"
                              :disabled="data.disabled"
                            />
                          </BInputGroup>
                        </template>
                      </DateRangePicker>
                    </BFormGroup>
                  </BCol>
                </BRow>
              </BCard>

              <BCard
                class="FormSectionCard FormSectionCard--subSection"
                tag="fieldset"
              >
                <BRow>
                  <BCol md="3">
                    <legend class="FormSectionCard__parentLabel">
                      Strøm
                    </legend>
                  </BCol>

                  <BCol md="5">
                    <BFormGroup
                      :id="`input-group-powerMeterNumber-${$_uid}`"
                      class="FormSectionCard__formGroup"
                      :label-for="`input-powerMeterNumber-${$_uid}`"
                    >
                      <template #label>
                        <span class="sr-only">Strøm </span>Målernummer
                      </template>
                      <BFormInput
                        :id="`input-powerMeterNumber-${$_uid}`"
                        v-model="form.meters.power.number"
                      />
                    </BFormGroup>
                  </BCol>

                  <BCol md="4">
                    <BFormGroup
                      :id="`input-group-powerMeterMileage-${$_uid}`"
                      class="FormSectionCard__formGroup"
                      :label-for="`input-powerMeterMileage-${$_uid}`"
                    >
                      <template #label>
                        <span class="sr-only">Strøm </span>Målerstand
                      </template>

                      <BInputGroup>
                        <BFormInput
                          :id="`input-powerMeterMileage-${$_uid}`"
                          v-model.number="form.meters.power.mileage"
                          type="number"
                          step="0.01"
                          min="0"
                        />

                        <template #append>
                          <BInputGroupText>
                            kWh
                          </BInputGroupText>
                        </template>
                      </BInputGroup>
                    </BFormGroup>
                  </BCol>
                </BRow>

                <hr>

                <BRow>
                  <BCol md="3">
                    <legend class="FormSectionCard__parentLabel">
                      Varmtvann
                    </legend>
                  </BCol>
                  <BCol md="5">
                    <BFormGroup
                      :id="`input-group-hotWaterMeterNumber-${$_uid}`"
                      class="FormSectionCard__formGroup"
                      :label-for="`input-hotWaterMeterNumber-${$_uid}`"
                    >
                      <template #label>
                        <span class="sr-only">Varmtvann </span>Målernummer
                      </template>
                      <BFormInput
                        :id="`input-hotWaterMeterNumber-${$_uid}`"
                        v-model="form.meters.hotWater.number"
                      />
                    </BFormGroup>
                  </BCol>

                  <BCol md="4">
                    <BFormGroup
                      :id="`input-group-hotWaterMeterMileage-${$_uid}`"
                      class="FormSectionCard__formGroup"
                      :label-for="`input-hotWaterMeterMileage-${$_uid}`"
                    >
                      <template #label>
                        <span class="sr-only">Varmtvann </span>Målerstand
                      </template>

                      <BInputGroup>
                        <BFormInput
                          :id="`input-hotWaterMeterMileage-${$_uid}`"
                          v-model.number="form.meters.hotWater.mileage"
                          type="number"
                          step="0.01"
                          min="0"
                        />

                        <template #append>
                          <BInputGroupText>
                            m³
                          </BInputGroupText>
                        </template>
                      </BInputGroup>
                    </BFormGroup>
                  </BCol>
                </BRow>

                <hr>

                <BRow>
                  <BCol md="3">
                    <legend class="FormSectionCard__parentLabel">
                      Kaldtvann
                    </legend>
                  </BCol>

                  <BCol md="5">
                    <BFormGroup
                      :id="`input-group-coldWaterMeterNumber-${$_uid}`"
                      class="FormSectionCard__formGroup"
                      :label-for="`input-coldWaterMeterNumber-${$_uid}`"
                    >
                      <template #label>
                        <span class="sr-only">Kaldtvann </span>Målernummer
                      </template>
                      <BFormInput
                        :id="`input-coldWaterMeterNumber-${$_uid}`"
                        v-model="form.meters.coldWater.number"
                      />
                    </BFormGroup>
                  </BCol>

                  <BCol md="4">
                    <BFormGroup
                      :id="`input-group-coldWaterMeterMileage-${$_uid}`"
                      class="FormSectionCard__formGroup"
                      :label-for="`input-coldWaterMeterMileage-${$_uid}`"
                    >
                      <template #label>
                        <span class="sr-only">Kaldtvann </span>Målerstand
                      </template>

                      <BInputGroup>
                        <BFormInput
                          :id="`input-coldWaterMeterMileage-${$_uid}`"
                          v-model.number="form.meters.coldWater.mileage"
                          type="number"
                          step="0.01"
                          min="0"
                        />

                        <template #append>
                          <BInputGroupText>
                            m³
                          </BInputGroupText>
                        </template>
                      </BInputGroup>
                    </BFormGroup>
                  </BCol>
                </BRow>

                <hr>

                <BRow>
                  <BCol md="3">
                    <legend class="FormSectionCard__parentLabel">
                      Gulvvarme
                    </legend>
                  </BCol>

                  <BCol md="5">
                    <BFormGroup
                      :id="`input-group-groundHeatingMeterNumber-${$_uid}`"
                      class="FormSectionCard__formGroup"
                      :label-for="`input-groundHeatingMeterNumber-${$_uid}`"
                    >
                      <template #label>
                        <span class="sr-only">Gulvvarme </span>Målernummer
                      </template>
                      <BFormInput
                        :id="`input-groundHeatingMeterNumber-${$_uid}`"
                        v-model="form.meters.groundHeating.number"
                      />
                    </BFormGroup>
                  </BCol>

                  <BCol md="4">
                    <BFormGroup
                      :id="`input-group-groundHeatingMeterMileage-${$_uid}`"
                      class="FormSectionCard__formGroup"
                      :label-for="`input-groundHeatingMeterMileage-${$_uid}`"
                    >
                      <template #label>
                        <span class="sr-only">Gulvvarme </span>Målerstand
                      </template>

                      <BInputGroup>
                        <BFormInput
                          :id="`input-groundHeatingMeterMileage-${$_uid}`"
                          v-model.number="form.meters.groundHeating.mileage"
                          type="number"
                          step="0.01"
                          min="0"
                        />

                        <template #append>
                          <BInputGroupText>
                            kWh
                          </BInputGroupText>
                        </template>
                      </BInputGroup>
                    </BFormGroup>
                  </BCol>
                </BRow>
              </BCard>

              <BCard
                class="FormSectionCard FormSectionCard--subSection"
                tag="fieldset"
              >
                <legend class="FormSectionCard__parentLabel pb-0">
                  Sjekkliste
                </legend>

                <RadioChecklistItem
                  v-for="option in miscInfoChecklistOptions"
                  :key="option.id"
                  v-model="form.miscInfoChecklist[option.id]"
                  :label="option.label"
                  :name="`miscInfoChecklist-${option.id}`"
                />
              </BCard>
            </BCard>

            <BCard class="FormSectionCard">
              <template #header>
                <h2 class="FormSectionCard__heading">
                  Utlevert og mottatt av kjøper
                </h2>
              </template>

              <BTableSimple stacked="sm">
                <BThead>
                  <BTr>
                    <BTh>
                      Beskrivelse
                    </BTh>
                    <BTh width="25%">
                      Antall (stk)
                    </BTh>
                    <BTh width="25%">
                      Utlevert (stk)
                    </BTh>
                  </BTr>
                </BThead>

                <BTbody>
                  <BTr
                    v-for="item in deliveriesItems"
                    :key="item.id"
                  >
                    <BTd>
                      {{ item.label }}
                    </BTd>
                    <BTd>
                      <label
                        :for="`input-deliveries-${item.id}-amount-${$_uid}`"
                        class="d-sm-none"
                      >
                        Antall (stk)
                      </label>
                      <BFormInput
                        :id="`input-deliveries-${item.id}-amount-${$_uid}`"
                        v-model="form.deliveries[item.id].amount"
                        type="number"
                      />
                    </BTd>
                    <BTd>
                      <label
                        :for="`input-deliveries-${item.id}-handed-${$_uid}`"
                        class="d-sm-none"
                      >
                        Utlevert (stk)
                      </label>
                      <BFormInput
                        :id="`input-deliveries-${item.id}-handed-${$_uid}`"
                        v-model="form.deliveries[item.id].handed"
                        type="number"
                      />
                    </BTd>
                  </BTr>
                </BTbody>
              </BTableSimple>
            </BCard>

            <BCard class="FormSectionCard">
              <template #header>
                <h2 class="FormSectionCard__heading">
                  Oppgjør
                </h2>
              </template>

              <RadioChecklistItem
                v-for="item in settlementOptions"
                :key="item.id"
                v-model="form.settlement[item.id]"
                :name="`settlementChecklist-${item.id}`"
              >
                <template #label>
                  <strong v-if="item.title">
                    {{ item.title }}<br>
                  </strong>
                  {{ item.label }}
                </template>
              </RadioChecklistItem>
            </BCard>

            <BCard class="FormSectionCard">
              <template #header>
                <h2 class="FormSectionCard__heading">
                  Tilbakehold
                </h2>
              </template>

              <BFormGroup class="FormSectionCard__formGroup">
                <RadioChecklistItem
                  v-model="form.retention.buyerInstructs"
                  :name="`retention-buyerInstructs`"
                  label="Kjøper instruerer tilbakehold"
                />

                <RadioChecklistItem
                  v-model="form.retention.partiesAgree"
                  :name="`retention-partiesAgree`"
                  label="Partene er enige om tilbakehold"
                />
              </BFormGroup>

              <h6 class="mb-2 mt-4">
                Tilbakeholdt
                <small
                  v-if="retentionTotal > 0"
                  class="text-muted ml-1"
                >
                  (Totalt kr {{ retentionTotal }})
                </small>
              </h6>

              <template v-if="form.retention.items.length > 0">
                <BCard
                  v-for="(item, key) in form.retention.items"
                  :key="key"
                  class="FormSectionCard FormSectionCard--subSection"
                  tag="fieldset"
                >
                  <BRow>
                    <BCol md="6">
                      <BFormGroup
                        :id="`input-group-retentionItem-${key}`"
                        class="FormSectionCard__formGroup mb-3 mb-md-0"
                        :label-for="`input-retentionItem-${key}`"
                        label="Beskrivelse"
                      >
                        <BFormInput
                          :id="`input-retentionItem-${key}`"
                          v-model="form.retention.items[key].item"
                        />
                      </BFormGroup>
                    </BCol>

                    <BCol
                      md="6"
                      class="d-flex align-items-end"
                    >
                      <BFormGroup
                        :id="`input-group-retentionAmount-${key}`"
                        class="FormSectionCard__formGroup mb-0 flex-grow-1"
                        :label-for="`input-retentionAmount-${key}`"
                        label="Beløp"
                      >
                        <BInputGroup>
                          <BFormInput
                            :id="`input-retentionAmount-${key}`"
                            v-model.number="form.retention.items[key].amount"
                            type="number"
                            step="0.01"
                            min="0"
                          />

                          <template #append>
                            <BInputGroupText>
                              kr
                            </BInputGroupText>
                          </template>
                        </BInputGroup>
                      </BFormGroup>

                      <div class="flex-grow-0 flex-shrink-1 ml-4">
                        <BButton
                          variant="outline-danger"
                          @click="deleteRetentionItem(key)"
                        >
                          <span class="sr-only">Fjern</span>
                          <FontAwesomeIcon :icon="['fas', 'trash']" />
                        </BButton>
                      </div>
                    </BCol>
                  </BRow>
                </BCard>
              </template>

              <div
                v-else
                class="text-muted my-3"
              >
                Det er ikke lagt til noen punkter.
              </div>

              <BButton
                variant="success"
                @click="addRetentionItem"
              >
                <FontAwesomeIcon :icon="['fas', 'plus']" />
                Legg til
              </BButton>
            </BCard>

            <BCard class="FormSectionCard">
              <template #header>
                <h2 class="FormSectionCard__heading">
                  Signering
                </h2>
              </template>

              <BFormGroup
                :id="`input-group-sign-location-${$_uid}`"
                class="FormSectionCard__formGroup"
                label="Sted"
                :label-for="`input-sign-location-${$_uid}`"
              >
                <BFormInput
                  :id="`input-sign-location-${$_uid}`"
                  v-model="form.sign.location"
                />
              </BFormGroup>

              <BFormGroup
                :id="`input-group-sign-date-${$_uid}`"
                class="FormSectionCard__formGroup"
                label="Dato"
                :label-for="`input-sign-date-${$_uid}`"
              >
                <DateRangePicker
                  ref="datePicker"
                  v-model="form.sign.date"
                  single-date-picker
                  show-dropdowns
                  :ranges="null"
                  :locale-data="dateRangePickerLocale"
                >
                  <template #input="data">
                    <BInputGroup>
                      <template #prepend>
                        <BInputGroupText>
                          <FontAwesomeIcon :icon="['fas', 'calendar-alt']" />
                        </BInputGroupText>
                      </template>

                      <BFormInput
                        :id="`input-sign-date-${$_uid}`"
                        :value="formatDate(data.startDate)"
                      />
                    </BInputGroup>
                  </template>
                </DateRangePicker>
              </BFormGroup>

              <BRow class="m-n3">
                <BCol
                  v-for="customerSignature in form.sign.customerSignatures"
                  :key="customerSignature.name"
                  class="p-3"
                  xl="6"
                >
                  <BFormGroup
                    :id="`input-group-sign-customer-${customerSignature.name.replace(/\s/, '-')}-${$_uid}`"
                    class="FormSectionCard__formGroup"
                    :label="customerSignature.name"
                    :label-for="`input-sign-customer-${customerSignature.name.replace(/\s/, '-')}-${$_uid}`"
                  >
                    <SignatureField
                      :id="`input-sign-customer-${customerSignature.name.replace(/\s/, '-')}-${$_uid}`"
                      :ref="`customerSignature-${customerSignature.name.replace(/\s/, '-')}`"
                      v-model="customerSignature.signature"
                    />

                    <BButton
                      class="mt-2"
                      variant="outline-secondary"
                      @click="$refs[`customerSignature-${customerSignature.name.replace(/\s/, '-')}`][0].clear()"
                    >
                      <FontAwesomeIcon :icon="['fas', 'undo']" />

                      Tilbakestill
                    </BButton>
                  </BFormGroup>
                </BCol>
              </BRow>

              <BRow class="m-n3">
                <BCol
                  class="p-3"
                  xl="6"
                >
                  <div class="d-inline-block">
                    <BFormGroup
                      :id="`input-group-sign-seller-${$_uid}`"
                      class="FormSectionCard__formGroup"
                      label="Selger"
                      :label-for="`input-sign-seller-${$_uid}`"
                    >
                      <BFormInput
                        :id="`input-sign-seller-${$_uid}`"
                        v-model="form.sign.seller"
                      />

                      <SignatureField
                        ref="sellerSignature"
                        v-model="form.sign.sellerSignature"
                        class="mt-2"
                      />

                      <BButton
                        class="mt-2"
                        variant="outline-secondary"
                        @click="$refs.sellerSignature.clear()"
                      >
                        <FontAwesomeIcon :icon="['fas', 'undo']" />

                        Tilbakestill
                      </BButton>
                    </BFormGroup>
                  </div>
                </BCol>

                <BCol
                  class="p-3"
                  xl="6"
                >
                  <div class="d-inline-block">
                    <BFormGroup
                      :id="`input-group-sign-consultant-${$_uid}`"
                      class="FormSectionCard__formGroup"
                      label="Konsulent"
                      :label-for="`input-sign-consultant-${$_uid}`"
                    >
                      <BFormInput
                        :id="`input-sign-consultant-${$_uid}`"
                        v-model="form.sign.consultant"
                      />

                      <SignatureField
                        ref="consultantSignature"
                        v-model="form.sign.consultantSignature"
                        class="mt-2"
                      />

                      <BButton
                        class="mt-2"
                        variant="outline-secondary"
                        @click="$refs.consultantSignature.clear()"
                      >
                        <FontAwesomeIcon :icon="['fas', 'undo']" />

                        Tilbakestill
                      </BButton>
                    </BFormGroup>
                  </div>
                </BCol>

                <BCol
                  class="p-3"
                  xl="6"
                >
                  <div class="d-inline-block">
                    <BFormGroup
                      :id="`input-group-sign-entrepreneur-${$_uid}`"
                      class="FormSectionCard__formGroup"
                      label="Entreprenør"
                      :label-for="`input-sign-entrepreneur-${$_uid}`"
                    >
                      <BFormInput
                        :id="`input-sign-entrepreneur-${$_uid}`"
                        v-model="form.sign.entrepreneur"
                      />

                      <SignatureField
                        ref="entrepreneurSignature"
                        v-model="form.sign.entrepreneurSignature"
                        class="mt-2"
                      />

                      <BButton
                        class="mt-2"
                        variant="outline-secondary"
                        @click="$refs.entrepreneurSignature.clear()"
                      >
                        <FontAwesomeIcon :icon="['fas', 'undo']" />

                        Tilbakestill
                      </BButton>
                    </BFormGroup>
                  </div>
                </BCol>
              </BRow>
            </BCard>

            <BRow class="my-4">
              <BCol
                md="6"
              >
                <template v-if="pdfUrl">
                  <div class="d-flex">
                    <BButton
                      size="lg"
                      type="submit"
                      variant="primary"
                      :disabled="isSubmitting"
                      class="flex-grow-0"
                    >
                      <span v-if="isSubmitting">
                        <BSpinner
                          variant="light"
                          small
                        />
                      </span>
                      <span v-else>
                        <FontAwesomeIcon :icon="['fas', 'file-plus']" />
                      </span>
                    </BButton>
                    <BButton
                      size="lg"
                      block
                      variant="success"
                      class="ml-4 flex-grow-1"
                      :href="pdfUrl"
                      target="_blank"
                    >
                      <FontAwesomeIcon :icon="['fas', 'download']" />
                      Last ned
                    </BButton>
                  </div>
                </template>
                <BButton
                  v-else
                  size="lg"
                  block
                  type="submit"
                  variant="primary"
                  :disabled="isSubmitting"
                >
                  <span v-if="isSubmitting">
                    <BSpinner
                      variant="light"
                      small
                    />
                    Genererer dokument…
                  </span>
                  <span v-else>
                    <FontAwesomeIcon :icon="['fas', 'file-plus']" />
                    Generer dokument
                  </span>
                </BButton>
              </BCol>

              <BCol md="6">
                <BButton
                  size="lg"
                  block
                  variant="secondary"
                  :disabled="isSaving"
                  @click="saveFormData"
                >
                  <span v-if="isSaving">
                    <BSpinner
                      variant="light"
                      small
                    />
                    Lagrer skjema…
                  </span>
                  <span v-else>
                    <FontAwesomeIcon :icon="['fas', 'save']" />
                    Lagre skjema
                  </span>
                </BButton>
              </BCol>
            </BRow>
          </BForm>
        </BCol>
      </BRow>
    </BContainer>
  </div>
</template>

<script>
import RadioChecklistItem from '@/shared/components/RadioChecklistItem'
import DateRangePicker from '@/shared/components/vue2-daterange-picker-dayjs/components/DateRangePicker'
import Dayjs from 'dayjs'
import Axios from 'axios'
import { dateFormat } from '@/shared/utils/dateRangePickerLocale'
import SignatureField from '@/admin/components/SignatureField'

export default {
  components: {
    SignatureField,
    DateRangePicker,
    RadioChecklistItem,
  },

  props: {
    apartment: {
      type: Object,
      required: true,
    },
    owners: {
      type: Array,
      required: true,
    },
    loginKey: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    form: {
      inspectionDate: new Date(),
      presentForCustomer: null,
      presentForSeller: null,
      presentForEntrepreneur: null,
      protocolDistribution: null,
      controlPointsChecklist: [],
      controlPointsChecklistComments: {},
      jointInsuranceCompany: null,
      jointInsuranceNumber: null,
      tempUsePermitDate: new Date(),
      certificateOfCompletionDate: new Date(),
      showTempUsePermitDate: false,
      showCertificateOfCompletionDate: false,
      meters: {
        power: {
          number: null,
          mileage: null,
        },
        hotWater: {
          number: null,
          mileage: null,
        },
        coldWater: {
          number: null,
          mileage: null,
        },
        groundHeating: {
          number: null,
          mileage: null,
        },
      },
      miscInfoChecklist: {},
      deliveries: {},
      settlement: {},
      retention: {
        buyerInstructs: null,
        partiesAgree: null,
        items: [],
      },
      sign: {
        location: null,
        date: new Date(),
        seller: null,
        sellerSignature: null,
        consultant: null,
        consultantSignature: null,
        entrepreneur: null,
        entrepreneurSignature: null,
        customerSignatures: [],
      },
    },
    isSubmitting: false,
    isSaving: false,
    dateRangePickerLocale: dateFormat,
    controlPointsOptions: [],
    miscInfoChecklistOptions: {},
    deliveriesItems: {},
    settlementOptions: [],
    pdfUrl: null,
  }),

  computed: {
    presentForCustomerTextareaRows () {
      return this.autoExpandTextareaRows(this.form.presentForCustomer)
    },

    presentForSellerTextareaRows () {
      return this.autoExpandTextareaRows(this.form.presentForSeller)
    },

    presentForEntrepreneurTextareaRows () {
      return this.autoExpandTextareaRows(this.form.presentForEntrepreneur)
    },

    protocolDistributionTextareaRows () {
      return this.autoExpandTextareaRows(this.form.protocolDistribution)
    },

    retentionTotal () {
      let total = 0

      this.form.retention.items.forEach(item => {
        total = total + item.amount
      })

      return total
    },

    individualCustomers: ({ form }) => (
      form.presentForCustomer
        ? form.presentForCustomer.split('\n').filter(Boolean)
        : []
    ),
  },

  watch: {
    individualCustomers: {
      handler (customers) {
        if (!customers) {
          return
        }

        // Removes customer signatures where the name is no longer present in individualCustomers
        this.form.sign.customerSignatures = this.form.sign.customerSignatures
          .filter((customerSignature) => {
            customers.includes(customerSignature.name)
          })

        // Adds new customer signatures that are new to individualCustomers
        customers
          .filter((customer) => (
            this.form.sign.customerSignatures.every((customerSignature) => (customerSignature.name !== customer))
          ))
          .forEach((customer) => {
            this.form.sign.customerSignatures.push({
              name: customer,
              signature: null,
            })
          })
      },
      immediate: true,
    },
  },

  mounted () {
    this.setFormOptions()
    this.prepopulateForm()
  },

  methods: {
    setFormOptions () {
      const projectStage = this.apartment.projectStage

      // Set control points checklist options
      if (Array.isArray(projectStage.protocolControlPoints)) {
        this.controlPointsOptions = projectStage.protocolControlPoints
      }

      // Set misc info checklist options
      if (Array.isArray(projectStage.protocolMiscInfo)) {
        this.miscInfoChecklistOptions = projectStage.protocolMiscInfo

        const checklist = {}

        projectStage.protocolMiscInfo.forEach(item => {
          checklist[item.id] = null
        })

        this.form.miscInfoChecklist = checklist
      }

      // Set deliveries items
      if (Array.isArray(projectStage.protocolDeliveries)) {
        this.deliveriesItems = projectStage.protocolDeliveries

        const deliveries = {}

        projectStage.protocolDeliveries.forEach(item => {
          deliveries[item.id] = {
            amount: null,
            handed: null,
          }
        })

        this.form.deliveries = deliveries
      }

      // Set settlement items
      if (Array.isArray(projectStage.protocolSettlement)) {
        this.settlementOptions = projectStage.protocolSettlement

        const settlement = {}

        projectStage.protocolSettlement.forEach(item => {
          settlement[item.id] = null
        })

        this.form.settlement = settlement
      }
    },

    prepopulateForm () {
      this.getFormData()

      if (this.form.presentForCustomer === null && typeof this.apartment.owner === 'string') {
        const owners = this.apartment.owner.split(', ')

        this.$set(this.form, 'presentForCustomer', owners.join('\n'))
      }

      if (this.form.jointInsuranceCompany === null && typeof this.apartment.projectStage.insuranceCompany === 'string') {
        this.$set(this.form, 'jointInsuranceCompany', this.apartment.projectStage.insuranceCompany)
      }

      if (this.form.jointInsuranceNumber === null && typeof this.apartment.projectStage.insuranceNumber === 'string') {
        this.$set(this.form, 'jointInsuranceNumber', this.apartment.projectStage.insuranceNumber)
      }

      if (this.form.sign.location === null && typeof this.apartment.address === 'string') {
        let address = this.apartment.address

        if (typeof this.apartment.postal === 'string') {
          address += `, ${this.apartment.postal}`

          if (typeof this.apartment.city === 'string') {
            address += ` ${this.apartment.city}`
          }
        }

        this.$set(this.form.sign, 'location', address)
      }

      const tempUsePermitDate = Dayjs(this.form.tempUsePermitDate)

      if (typeof this.apartment.projectStage.tempUsePermitDate === 'string' && !Dayjs().isSame(tempUsePermitDate)) {
        const newTempUsePermitDate = Dayjs(this.apartment.projectStage.tempUsePermitDate)

        if (newTempUsePermitDate.isValid()) {
          this.$set(this.form, 'tempUsePermitDate', newTempUsePermitDate.toDate())
          this.$set(this.form, 'showTempUsePermitDate', true)
        }
      }

      const certificateOfCompletionDate = Dayjs(this.form.certificateOfCompletionDate)

      if (typeof this.apartment.projectStage.certificateOfCompletionDate === 'string' && !Dayjs().isSame(certificateOfCompletionDate)) {
        const newCertificateOfCompletionDate = Dayjs(this.apartment.projectStage.certificateOfCompletionDate)

        if (newCertificateOfCompletionDate.isValid()) {
          this.$set(this.form, 'certificateOfCompletionDate', newCertificateOfCompletionDate.toDate())
          this.$set(this.form, 'showCertificateOfCompletionDate', true)
        }
      }
    },

    formatDate (data) {
      return Dayjs(data).format('DD. MMMM YYYY')
    },

    submitForm () {
      this.isSubmitting = true

      Axios.post(`/api/apartments/takeoverProtocol/${this.apartment.id}`, this.form).then(response => {
        this.pdfUrl = `/api/apartments/takeoverProtocol/${this.apartment.id}`

        this.isSubmitting = false
      }).catch(error => {
        this.$bvToast.toast('Kunne ikke generere dokument.', {
          variant: 'danger',
          title: 'Noe gikk galt',
        })

        console.error(error)

        this.isSubmitting = false
      })
    },

    saveFormData () {
      this.isSaving = true

      Axios.post(`/api/apartments/takeoverProtocol/${this.apartment.id}/formData`, this.form).then(response => {
        this.$bvToast.toast('Skjemaet ble lagret.', {
          variant: 'success',
          title: 'Skjema lagret',
        })

        this.isSaving = false
      }).catch(error => {
        this.$bvToast.toast('Kunne ikke lagre skjema.', {
          variant: 'danger',
          title: 'Noe gikk galt',
        })

        console.error(error)

        this.isSaving = false
      })
    },

    getFormData () {
      const data = this.apartment.takeoverFormData

      if (data !== null) {
        if (Object.prototype.hasOwnProperty.call(data, 'inspectionDate')) {
          const date = Dayjs(data.inspectionDate)

          if (date.isValid()) {
            data.inspectionDate = date.toDate()
          }
        }

        if (Object.prototype.hasOwnProperty.call(data, 'tempUsePermitDate')) {
          const date = Dayjs(data.tempUsePermitDate)

          if (date.isValid()) {
            data.tempUsePermitDate = date.toDate()
          }
        }

        if (Object.prototype.hasOwnProperty.call(data, 'certificateOfCompletionDate')) {
          const date = Dayjs(data.certificateOfCompletionDate)

          if (date.isValid()) {
            data.certificateOfCompletionDate = date.toDate()
          }
        }

        if (Object.prototype.hasOwnProperty.call(data, 'presentForEntrepreneur')) {
          this.form.presentForEntrepreneur = data.presentForEntrepreneur
        }

        if (Object.prototype.hasOwnProperty.call(data, 'presentForSeller')) {
          this.form.presentForSeller = data.presentForSeller
        }

        if (Object.prototype.hasOwnProperty.call(data, 'sign')) {
          if (Object.prototype.hasOwnProperty.call(data.sign, 'date')) {
            const date = Dayjs(data.sign.date)

            if (date.isValid()) {
              data.sign.date = date.toDate()
            }
          }

          this.form.sign.consultant = data.sign.consultant
          this.form.sign.entrepreneur = data.sign.entrepreneur
          this.form.sign.location = data.sign.location
          this.form.sign.seller = data.sign.seller
        }
      }
    },

    autoExpandTextareaRows (value) {
      const min = 2

      if (typeof value !== 'string') {
        return min
      }

      const rows = value.split('\n')

      return rows.length > min ? rows.length : min
    },

    addcontrolPointsChecklistComment (id) {
      if (!this.controlPointsChecklistItemHasComment(id)) {
        this.$set(this.form.controlPointsChecklistComments, `item-${id}`, '')
      }
    },

    removecontrolPointsChecklistComment (id) {
      if (this.controlPointsChecklistItemHasComment(id)) {
        this.$delete(this.form.controlPointsChecklistComments, `item-${id}`)
      }
    },

    controlPointsChecklistItemHasComment (id) {
      return Object.prototype.hasOwnProperty.call(this.form.controlPointsChecklistComments, `item-${id}`)
    },

    addRetentionItem () {
      this.form.retention.items.push({
        item: null,
        amount: null,
      })
    },

    deleteRetentionItem (key) {
      this.$delete(this.form.retention.items, key)
    },
  },
}
</script>
