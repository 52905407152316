<template>
  <BCard
    class="MessageCard"
    no-body
  >
    <BCardHeader
      class="MessageCard__header"
      header-tag="header"
    >
      <div class="MessageCard__headerContent">
        <div class="MessageCard__metaItem">
          <span class="MessageCard__metaKey">Fra:</span>
          <span class="MessageCard__metaValue">{{ sender }}</span>
        </div>

        <div
          v-if="recipient"
          class="MessageCard__metaItem"
        >
          <span class="MessageCard__metaKey">Til:</span>
          <span class="MessageCard__metaValue">{{ recipient }}</span>
        </div>
      </div>

      <BButton
        class="MessageCard__replyButton"
        size="sm"
        variant="primary"
        :href="`/issues/${id}/messages/`"
      >
        <FontAwesomeIcon
          class="MessageCard__buttonIcon"
          :icon="['fas', 'reply']"
        />
        {{ replyButtonLabel }}
      </BButton>
    </BCardHeader>

    <BCardBody
      ref="message"
      :class="messageClasses"
    >
      <div v-html="message" />
      <button
        v-if="messageCollapsed"
        v-b-tooltip.bottom.hover
        title="Vis hele meldingen"
        class="MessageCard__expandButton"
        @click="expandMessage"
      >
        <FontAwesomeIcon :icon="['fas', 'chevron-double-down']" />
      </button>
    </BCardBody>

    <BCardFooter class="MessageCard__footer">
      <span
        v-b-tooltip.hover
        class="MessageCard__timestamp"
        :title="date"
      >
        {{ relativeTime }}
      </span>
      <span>
        Sak <a :href="`/issues/${id}`">#{{ id }}</a>
      </span>
    </BCardFooter>
  </BCard>
</template>

<script>
export default {
  props: {
    sender: {
      type: String,
      required: true,
    },
    recipient: {
      type: String,
      default: null,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    relativeTime: {
      type: String,
      required: true,
    },
    replyButtonLabel: {
      type: String,
      default: 'Svar',
    },
  },

  data: () => ({
    messageCollapsible: false,
    messageCollapsed: false,
  }),

  computed: {
    messageClasses () {
      return {
        MessageCard__message: true,
        'MessageCard__message--collapsed': this.messageCollapsed,
        'MessageCard__message--expanded': this.messageCollapsible && !this.messageCollapsed,
      }
    },
  },

  mounted () {
    const messageEl = this.$refs.message

    if (messageEl.scrollHeight > messageEl.clientHeight) {
      this.messageCollapsible = true
      this.messageCollapsed = true
    }
  },

  methods: {
    expandMessage () {
      this.messageCollapsed = false
    },
  },
}
</script>
