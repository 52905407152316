import Vue from 'vue'

export const namespaced = true

export const state = {
  tables: {},
}

export const mutations = {
  setTableSettings (state, payload) {
    const table = payload.table
    const settings = payload.settings

    if (!state.tables[table]) {
      state.tables[table] = {}
    }

    Vue.set(state.tables, table, settings)
  },
  setTableVisibleFields (state, payload) {
    const table = payload.table
    const fields = payload.fields

    if (!state.tables[table]) {
      Vue.set(state.tables, table, {
        visibleFields: [],
      })
    }

    Vue.set(state.tables[table], 'visibleFields', fields)
  },
}

export const actions = {}

export const getters = {
  tableSettings: (state) => (table) => {
    if (state.tables[table]) {
      return state.tables[table]
    }

    return null
  },

  tableVisibleFields: (state) => (table) => {
    if (state.tables[table] && state.tables[table].visibleFields) {
      return state.tables[table].visibleFields
    }

    return []
  },
}
