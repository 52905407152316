<template>
  <div class="Page__mainView">
    <BContainer fluid>
      <BRow>
        <BCol>
          <h1 class="Page__mainHeading">
            <FontAwesomeIcon
              class="Page__mainHeadingIcon"
              :icon="['fas', 'building']"
            />
            {{ project.name }}
          </h1>
        </BCol>
      </BRow>
    </BContainer>

    <section class="MasterTabs">
      <nav class="MasterTabs__navWrapper">
        <BNav
          tabs
          class="MasterTabs__nav"
        >
          <BNavItem
            v-for="tab in tabs"
            :key="tab.name"
            :to="tab.to"
            class="MasterTabs__tab"
            :exact="true"
            exact-active-class="active"
          >
            {{ tab.label }}
          </BNavItem>
        </BNav>
      </nav>

      <div class="MasterTabs__content">
        <RouterView
          :key="$route.name + ($route.params.status ? $route.params.status : null)"
          v-bind="[$props, $attrs]"
        />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
    apartmentsCount: {
      type: [Number, String],
      default: null,
    },
    issuesCounts: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
  }),

  computed: {
    tabs () {
      return [
        {
          to: {
            name: 'ProjectContactPersonsView',
          },
          label: 'Kontaktpersoner',
        },
        {
          to: {
            name: 'ProjectApartmentsView',
          },
          label: `Leiligheter (${this.apartmentsCount})`,
        },
        {
          to: {
            name: 'ProjectIssuesView',
            params: {
              status: 'open',
            },
          },
          label: `Åpne saker (${this.issuesCounts.open})`,
        },
        {
          to: {
            name: 'ProjectIssuesView',
            params: {
              status: 'oneyear',
            },
          },
          label: `1-års befaring (${this.issuesCounts.oneyear})`,
        },
        {
          to: {
            name: 'ProjectIssuesView',
            params: {
              status: 'closed',
            },
          },
          label: `Lukkede saker (${this.issuesCounts.closed})`,
        },
      ]
    },
  },
}
</script>
