<template>
  <BCard
    class="CustomerDetailsCard"
    bg-variant="light"
  >
    <h3 class="CustomerDetailsCard__heading">
      {{ customer.firstname }} {{ customer.surname }}
    </h3>

    <BTable
      striped
      fixed
      small
      :items="customerDetails(customer)"
      :fields="customerDetailsFields"
      class="CustomerDetailsCard__table"
    >
      <template v-slot:cell(label)="data">
        <strong>{{ data.value }}</strong>
      </template>
    </BTable>

    <template v-slot:footer>
      <div class="CustomerDetailsCard__footerActions">
        <BDropdown
          size="sm"
          variant="primary"
          class="CustomerDetailsCard__footerActionsButton"
          :disabled="resettingPassword"
        >
          <template
            v-if="resettingPassword"
            v-slot:button-content
          >
            <FontAwesomeIcon
              spin
              :icon="['fas', 'sync']"
            />
            Nullstiller passord
          </template>

          <template
            v-else
            v-slot:button-content
          >
            <FontAwesomeIcon :icon="['fas', 'sync']" />
            Nullstill passord
          </template>

          <BDropdownItem
            v-if="customer.phone"
            @click="resetPassword(false, true)"
          >
            Send nytt via SMS
          </BDropdownItem>

          <BDropdownItem
            @click="resetPassword(true, false)"
          >
            Send nytt via e-post
          </BDropdownItem>

          <BDropdownItem
            v-if="customer.phone"
            @click="resetPassword(true, true)"
          >
            Send nytt via både e-post og SMS
          </BDropdownItem>
        </BDropdown>
        <BButton
          size="sm"
          variant="success"
          class="CustomerDetailsCard__footerActionsButton"
          :href="loginUrl"
          target="_blank"
        >
          <FontAwesomeIcon :icon="['fas', 'user-lock']" />
          Logg inn som denne kunden
        </BButton>
      </div>
    </template>
  </BCard>
</template>

<script>
import Axios from 'axios'

export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
    apartmentId: {
      type: [String, Number],
      required: true,
    },
    loginKey: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    customerDetailsFields: [
      {
        key: 'label',
        thStyle: {
          display: 'none',
        },
      },
      {
        key: 'value',
        thStyle: {
          display: 'none',
        },
      },
    ],
    resettingPassword: false,
  }),

  computed: {
    loginUrl () {
      return `https://minside.vestaksen.no/loginAsCustomer/${this.customer.id}/${this.apartmentId}/?key=${this.loginKey}`
    },
  },

  methods: {
    customerDetails (customer) {
      return [
        {
          key: 'id',
          label: 'Kundenummer',
          value: customer.id,
        },
        {
          key: 'email',
          label: 'E-post',
          value: customer.mail,
        },
        {
          key: 'phone',
          label: 'Telefon',
          value: customer.phone,
        },
      ]
    },

    resetPassword (notifyEmail = true, notifySMS = false) {
      this.resettingPassword = true

      Axios.get(`/api/auth/password/reset/${this.customer.id}`, {
        params: {
          notifyEmail: notifyEmail ? 1 : 0,
          notifySMS: notifySMS ? 1 : 0,
        },
      }).then(response => {
        this.resettingPassword = false

        this.$bvToast.toast(`Passordet til ${this.customer.firstname} ${this.customer.surname} ble nullstilt.`, {
          variant: 'success',
          title: 'Passord nullstilt',
        })
      }).catch(error => {
        this.resettingPassword = false

        console.error(error)

        this.$bvToast.toast(`Passordet til ${this.customer.firstname} ${this.customer.surname} ble ikke nullstilt.`, {
          variant: 'error',
          title: 'Noe gikk galt.',
        })
      })
    },
  },
}
</script>
