<template>
  <IssueMessages
    :issue="issue"
    type="internal"
  />
</template>

<script>
import IssueMessages from '../components/IssueMessages'

export default {
  components: {
    IssueMessages,
  },

  props: {
    issue: {
      type: Object,
      required: true,
    },
  },
}
</script>
