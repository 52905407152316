import Axios from 'axios'

export const namespaced = true

export const state = {
  projectStages: [],
  apartments: [],
}

export const mutations = {
  SET_PROJECT_STAGES (state, projectStages) {
    state.projectStages = projectStages
  },

  SET_APARTMENTS (state, apartments) {
    state.apartments = apartments
  },
}

export const actions = {
  async getProjectStages ({ state, commit }, refresh = false) {
    // We check that contactPersonIds exist on the projectStages, so that we can refetch them if an outdated version is cached
    if (state.projectStages?.length > 0 && Array.isArray(state.projectStages[0].contactPersonIds) && !refresh) {
      return
    }

    const response = await Axios.get('/api/apartments/projectStages')

    commit('SET_PROJECT_STAGES', response.data.data)
  },

  async getApartments ({ state, commit }, refresh = false) {
    if (state.apartments?.length > 0 && !refresh) {
      return
    }

    const repsonse = await Axios.get('/api/apartments/apartments')

    commit('SET_APARTMENTS', repsonse.data.data)
  },

  async refresh ({ dispatch }) {
    dispatch('getProjectStages', true)
    dispatch('getApartments', true)
  },
}
