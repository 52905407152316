<template>
  <div>
    <BButton
      v-if="isSubmitting"
      variant="success"
      disabled
    >
      <BSpinner
        variant="light"
        small
      />
    </BButton>

    <BButton
      v-else
      variant="success"
      @click="markAsComplete"
    >
      <FontAwesomeIcon :icon="['fas', 'check']" />
      Merk som fullført
    </BButton>
  </div>
</template>

<script>
import Axios from 'axios'
import EventBus from '@/shared/config/eventBus'

export default {
  props: {
    deadlineId: {
      type: [String, Number],
      required: true,
    },
  },

  data: () => ({
    isSubmitting: false,
  }),

  methods: {
    markAsComplete () {
      this.isSubmitting = true

      Axios.post(`/api/deadlines/${this.deadlineId}/complete/`).then(response => {
        this.$bvToast.toast('Fristen ble markert som fullført.', {
          variant: 'success',
          title: 'Frist fullført',
        })

        EventBus.$emit('deadlineUpdated', response)
        this.isSubmitting = false
      }).catch(error => {
        this.isSubmitting = false

        this.$bvToast.toast('Kunne ikke markere frist som fullført.', {
          variant: 'danger',
          title: 'Noe gikk galt',
        })

        console.error(error)
      })
    },
  },
}
</script>
