<template>
  <div class="IssueDeadlinesActions">
    <div class="IssueDeadlinesActions__group">
      <BButton
        v-b-modal.new-deadline-modal
        class="IssueDeadlinesActions__button"
        variant="primary"
      >
        <FontAwesomeIcon :icon="['fas', 'plus']" />
        Lag ny frist
      </BButton>
    </div>

    <div class="IssueDeadlinesActions__group">
      <BDropdown
        class="IssueDeadlinesActions__button"
        variant="secondary"
        right
      >
        <template v-slot:button-content>
          <FontAwesomeIcon :icon="['fas', 'calendar-alt']" />
          Hent din kalender
        </template>

        <BDropdownItem
          v-for="superUser in superUsers"
          :key="superUser.id"
          :href="`/api/deadlines/export/calendar/${superUser.id}`"
          target="_blank"
          download
        >
          {{ superUser.firstname }} {{ superUser.surname }}
        </BDropdownItem>
      </BDropdown>
    </div>

    <IssueDeadlinesModalCreate
      id="new-deadline-modal"
      :issue-id="issue.id"
    />
  </div>
</template>

<script>
import IssueDeadlinesModalCreate from '../components/IssueDeadlinesModalCreate'

export default {
  components: {
    IssueDeadlinesModalCreate,
  },

  props: {
    issue: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    superUsers: [],
  }),

  mounted () {
    this.getSuperUsers()
  },

  methods: {
    async getSuperUsers () {
      await this.$store.dispatch('superUsers/getAll').then(superUsers => {
        this.superUsers = superUsers
      })
    },
  },
}
</script>
