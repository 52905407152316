<template>
  <div>
    <div class="TableActions">
      <BForm
        class="TableActions__search"
        inline
        @submit.stop.prevent
      >
        <label
          :for="`filter-input-${$_uid}`"
          class="TableActions__searchLabel"
        >
          Søk:
        </label>
        <BFormInput
          :id="`filter-input-${$_uid}`"
          v-model="filter"
          debounce="250"
          placeholder="Minst 2 tegn"
        />
      </BForm>

      <BDropdown
        class="TableActions__button"
        right
      >
        <template v-slot:button-content>
          <FontAwesomeIcon :icon="['fas', 'columns']" />
          Vis/skjul
        </template>

        <BDropdownForm>
          <BFormCheckboxGroup
            v-model="visibleFields"
            class="text-nowrap"
            :options="visibleFieldsOptions"
            stacked
          />
        </BDropdownForm>
      </BDropdown>

      <BDropdown
        class="TableActions__button"
        right
      >
        <template v-slot:button-content>
          <FontAwesomeIcon :icon="['fas', 'file-export']" />
          Eksporter
        </template>

        <BDropdownItem
          download
          target="_blank"
          :href="`/api/apartments/export?${query}`"
          @click="exportToast"
        >
          Denne siden ({{ currentPageItems }} leiligheter)
        </BDropdownItem>

        <BDropdownItem
          download
          target="_blank"
          :href="`/api/apartments/export/all?${query}`"
          @click="exportToast"
        >
          Alle sider ({{ totalItems }} leiligheter)
        </BDropdownItem>
      </BDropdown>
    </div>

    <BTable
      :id="tableId"
      striped
      :busy.sync="isBusy"
      :items="dataProvider"
      :fields="fieldsFiltered"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      responsive
    >
      <template v-slot:table-busy>
        <div class="text-center my-4">
          <BSpinner class="d-block mx-auto mb-2" />
          <strong>Laster…</strong>
        </div>
      </template>

      <template v-slot:cell(projectStage)="data">
        <a :href="`/projects/${data.value.id}`">
          {{ data.value.name }}
        </a>
      </template>

      <template v-slot:cell(aptNumber)="data">
        <a :href="`/apartments/${data.item.id}`">
          {{ data.value }}
        </a>
      </template>
    </BTable>

    <BPagination
      v-model="currentPage"
      :per-page="perPage"
      :total-rows="totalItems"
      :aria-controls="tableId"
    />
  </div>
</template>

<script>
import Axios from 'axios'
import buildQueryString from '@/shared/utils/buildQueryString'
import { mapMutations, mapGetters } from 'vuex'

export default {
  props: {
    tableId: {
      type: String,
      default: 'apartments-table',
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    hideFields: {
      type: Array,
      default: () => ([]),
    },
  },

  data: () => ({
    isBusy: false,
    perPage: 25,
    currentPage: 1,
    totalItems: 0,
    filter: null,
    currentFilter: null,
    query: null,
    fields: [
      {
        key: 'projectStage',
        label: 'Byggetrinn',
        sortable: true,
      },
      {
        key: 'aptNumber',
        label: 'Leilighetsnummer',
        sortable: true,
      },
      {
        key: 'aptFloor',
        label: 'Etasje',
        sortable: true,
      },
      {
        key: 'groundSize',
        label: 'Størrelse (BRA)',
        sortable: true,
      },
      {
        key: 'aptRooms',
        label: 'Antall rom',
        sortable: true,
      },
      {
        key: 'ownerNames',
        label: 'Eier',
        sortable: false,
      },
      {
        key: 'ownerEmail',
        label: 'E-postadresse',
        sortable: false,
      },
      {
        key: 'ownerPhoneNumber',
        label: 'Telefonnummer',
        sortable: false,
      },
    ],
  }),

  computed: {
    fieldsFiltered () {
      return this.fields.filter(field => {
        return this.fieldIsVisible(field.key)
      })
    },

    visibleFieldsOptions () {
      return this.fields.map(field => {
        return {
          value: field.key,
          text: field.label,
        }
      })
    },

    visibleFields: {
      get () {
        return this.getVisibleFields(this.tableId)
      },
      set (value) {
        this.setTableVisibleFields({
          table: this.tableId,
          fields: value,
        })
      },
    },

    currentPageItems () {
      return this.totalItems > this.perPage ? this.perPage : this.totalItems
    },

    ...mapGetters({
      getVisibleFields: 'settings/tableVisibleFields',
    }),
  },

  mounted () {
    if (!this.visibleFields.length) {
      const fields = this.fields.filter(field => {
        return !this.hideFields.includes(field.key)
      }).map(field => {
        return field.key
      })

      this.visibleFields = fields
    }
  },

  methods: {
    dataProvider (ctx) {
      const query = { ...this.filters }

      query.page = ctx.currentPage

      if (ctx.sortBy) {
        query.sortBy = ctx.sortBy
        query.sortDirection = ctx.sortDesc ? 'desc' : 'asc'
      }

      if (ctx.filter && ctx.filter.length > 1) {
        query.filter = ctx.filter

        if (query.filter !== this.currentFilter) {
          query.page = 1
          this.currentFilter = ctx.filter
        }
      }

      this.query = this.buildQueryString(query)

      const request = Axios.get(`/api/apartments?${this.query}`)

      return request.then(response => {
        const data = response.data.data
        const items = data.items

        this.totalItems = data.totalItems
        this.perPage = data.itemsPerPage
        this.currentPage = data.page

        return items
      }).catch(error => {
        this.$bvToast.toast('Kunne ikke hente leiligheter.', {
          variant: 'danger',
          title: 'Noe gikk galt',
        })

        console.error(error)

        return []
      })
    },

    buildQueryString (data) {
      return buildQueryString(data)
    },

    fieldIsVisible (key) {
      return this.visibleFields.some(visibleField => {
        return visibleField === key
      })
    },

    exportToast () {
      this.$bvToast.toast('Eksport av leiligheter har startet. Dersom du har valgt å eksportere mange leiligheter kan det ta opptil flere minutter.', {
        variant: 'success',
        title: 'Eksport startet',
      })
    },

    ...mapMutations({
      setTableVisibleFields: 'settings/setTableVisibleFields',
    }),
  },
}
</script>
