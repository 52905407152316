<template>
  <BButton
    :variant="variant"
    :size="size"
    :disabled="isLoading"
    @click="deleteContactPerson"
  >
    <span v-if="isLoading">
      <FontAwesomeIcon
        :icon="['fas', 'circle-notch']"
        spin
      />
      Fjerner…
    </span>
    <span v-else>
      <FontAwesomeIcon :icon="['fas', 'user-times']" />
      Fjern
    </span>
  </BButton>
</template>

<script>
import Axios from 'axios'

export default {
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    contactPersonId: {
      type: Number,
      required: true,
    },
    size: {
      type: String,
      default: 'sm',
    },
    variant: {
      type: String,
      default: 'warning',
    },
  },

  data: () => ({
    isLoading: false,
  }),

  methods: {
    deleteContactPerson () {
      this.isLoading = true

      Axios.delete(`/api/projects/${this.projectId}/contactPersons/${this.contactPersonId}`).then(response => {
        this.$bvToast.toast('Kontaktpersonen ble fjernet.', {
          variant: 'success',
          title: 'Kontaktperson fjernet',
        })

        this.$emit('contactPersonDeleted', response)
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false

        this.$bvToast.toast('Kunne ikke fjerne kontaktperson.', {
          variant: 'danger',
          title: 'Noe gikk galt',
        })

        console.error(error)
      })
    },
  },
}
</script>
