<template>
  <div>
    <BButton
      :id="`popover-field-${$_uid}`"
      :size="buttonSize"
      :variant="buttonVariant"
      :block="buttonBlock"
    >
      {{ field ? capitalize(field.name) : 'Ikke satt' }}
    </BButton>

    <BPopover
      ref="popover"
      :target="`popover-field-${$_uid}`"
      triggers="click blur"
      placement="top"
      boundary="window"
      @show="popoverShow"
      @shown="popoverShown"
    >
      <BForm
        inline
        @submit.prevent="submitForm"
      >
        <BInputGroup>
          <BFormSelect
            ref="formSelect"
            v-model="selectedField"
            :disabled="isBusy"
          >
            <optgroup
              v-for="category in availableFields"
              :key="category.key"
              :label="category.name"
            >
              <option
                v-for="field in category.children"
                :key="field.id"
                :value="field"
              >
                {{ capitalize(field.name) }}
              </option>
            </optgroup>
          </BFormSelect>

          <BInputGroupAppend>
            <BButton
              v-if="isBusy"
              disabled
            >
              <b-spinner
                variant="light"
                small
              />
            </BButton>
            <BButton
              v-else
              type="submit"
              variant="primary"
            >
              Lagre
            </BButton>
          </BInputGroupAppend>
        </BInputGroup>
      </BForm>
    </BPopover>
  </div>
</template>

<script>
import Axios from 'axios'
export default {
  props: {
    currentField: {
      type: [Object, String],
      default: null,
    },
    issueId: {
      type: [Number, String],
      required: true,
    },
    buttonVariant: {
      type: String,
      default: 'light-gray',
    },
    buttonSize: {
      type: String,
      default: 'sm',
    },
    buttonBlock: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    field: null,
    selectedField: null,
    isBusy: true,
    availableFields: [],
    popoverShownBefore: false,
  }),

  mounted () {
    this.field = this.currentField
    this.selectedField = this.currentField
  },

  methods: {
    popoverShow () {
      if (!this.popoverShownBefore || !this.availableFields.length) {
        this.popoverShownBefore = true

        this.getFields()
      }
    },

    popoverShown () {
      const formSelect = this.$refs.formSelect

      if (formSelect) {
        formSelect.$el.focus()
      }
    },

    capitalize (s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },

    submitForm () {
      if (this.selectedField && this.selectedField !== this.field) {
        this.isBusy = true

        Axios.put(`/api/issues/${this.issueId}/`, {
          areaType: this.selectedField.id,
        }).then(response => {
          this.field = this.selectedField
          this.$refs.popover.$emit('close')
        }).finally(response => {
          this.isBusy = false
        })
      }
    },

    async getFields () {
      await this.$store.dispatch('issues/getFields')

      this.availableFields = this.$store.state.issues.fields
      this.isBusy = false
    },
  },
}
</script>
