<template>
  <BCard
    class="DashboardCard"
    no-body
  >
    <BCardHeader class="d-flex align-items-center justify-content-between">
      <h6>Kommentar</h6>

      <div class="m-n1">
        <BButton
          v-if="showEditCommentButton"
          variant="outline-primary"
          size="sm"
          class="m-1"
          @click="startCommentEditing"
        >
          <FontAwesomeIcon
            :icon="['fas', 'pencil']"
            class="mr-1"
          />

          Rediger
        </BButton>

        <BButton
          v-if="showSaveCommentButton"
          variant="outline-success"
          size="sm"
          class="m-1"
          :disabled="saveCommentButtonDisabled"
          @click="saveComment"
        >
          <FontAwesomeIcon
            :icon="saveCommentButtonIcon"
            :pulse="saveCommentButtonIconPulse"
            class="mr-1"
          />

          Lagre
        </BButton>

        <BButton
          v-if="showCancelCommentEditingButton"
          variant="danger"
          size="sm"
          class="m-1"
          :disabled="cancelCommentEditingButtonDisabled"
          @click="cancelCommentEditing"
        >
          <FontAwesomeIcon
            :icon="['fas', 'times']"
            class="mr-1"
          />

          Avbryt
        </BButton>
      </div>
    </BCardHeader>

    <BCardBody>
      <BTextarea
        v-if="editingComment"
        v-model="comment"
      />

      <template v-else>
        {{ issue.comment }}
      </template>
    </BCardBody>
  </BCard>
</template>

<script>
import Axios from 'axios'

export default {
  name: 'IssueDetailsComment',

  props: {
    issue: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    editingComment: false,
    comment: '',
    savingComment: false,
  }),

  computed: {
    showEditCommentButton: ({ editingComment }) => (!editingComment),

    showCancelCommentEditingButton: ({ editingComment }) => (editingComment),

    showSaveCommentButton: ({ editingComment }) => (editingComment),

    saveCommentButtonDisabled: ({ savingComment }) => (savingComment),

    saveCommentButtonIcon: ({ savingComment }) => (
      savingComment
        ? ['fas', 'spinner']
        : ['fas', 'check']
    ),

    saveCommentButtonIconPulse: ({ savingComment }) => (savingComment),

    cancelCommentEditingButtonDisabled: ({ savingComment }) => (savingComment),
  },

  methods: {
    startCommentEditing () {
      this.editingComment = true
      this.comment = this.issue.comment
    },

    cancelCommentEditing () {
      this.editingComment = false
      this.comment = ''
    },

    async saveComment () {
      this.savingComment = true

      try {
        if (this.comment !== this.issue.comment) {
          await Axios.put(`/api/issues/${this.issue.id}`, {
            comment: this.comment,
          })
        }

        this.editingComment = false
        this.issue.comment = this.comment
      } catch {
        this.$bvToast.toast('Kunne ikke lagre kommentar.', {
          variant: 'danger',
          title: 'Noe gikk galt',
        })
      } finally {
        this.savingComment = false
      }
    },
  },
}
</script>
