<template>
  <BForm>
    <BFormGroup
      :id="`input-group-standardReply-${$_uid}`"
      label="Hent standardmelding"
      :label-for="`input-standardReply-${$_uid}`"
      @submit.stop.prevent
    >
      <BFormSelect
        :id="`input-standardReply-${$_uid}`"
        v-model="selectedReply"
        :options="standardReplyOptions"
        :disabled="isLoading"
        @change="standardReplyChanged"
      />
    </BFormGroup>

    <template v-if="showApplyStandardReplyButton">
      <BFormText class="mb-2">
        NB! Standardmeldingen vil erstatte innholdet i melding-feltet.
      </BFormText>

      <BButton
        variant="success"
        @click="applyStandardReply"
      >
        <FontAwesomeIcon :icon="['fas', 'file-edit']" />
        Bruk standardmelding
      </BButton>
    </template>
  </BForm>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    isLoading: true,
    selectedReply: null,
    standardReplies: [],
    showApplyStandardReplyButton: false,
  }),

  computed: {
    standardReplyOptions () {
      if (!this.standardReplies.length) {
        return [{ value: null, text: 'Laster…' }]
      }

      return [
        {
          value: null,
          text: 'Velg standardmelding',
        },
        ...this.standardReplies.map(reply => {
          return {
            value: parseInt(reply.id),
            text: reply.name,
          }
        }),
      ]
    },
  },

  mounted () {
    this.getStandardReplies()
  },

  methods: {
    standardReplyChanged () {
      if (this.message) {
        this.showApplyStandardReplyButton = true
      } else {
        this.applyStandardReply()
      }
    },

    applyStandardReply () {
      const selectedReplyMessage = this.standardReplies.find(reply => {
        return reply.id === this.selectedReply
      })

      this.$bvToast.toast('Standardmelding valgt.', {
        variant: 'success',
      })

      this.showApplyStandardReplyButton = false
      this.$emit('apply', selectedReplyMessage ? selectedReplyMessage.answer : '')
    },

    async getStandardReplies () {
      await this.$store.dispatch('messages/getStandardReplies', this.type).then(standardReplies => {
        this.standardReplies = standardReplies
        this.isLoading = false
      })
    },
  },
}
</script>
