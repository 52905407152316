import Vue from 'vue'

import store from '../config/vuex/store'

import ApartmentsTable from '../components/ApartmentsTable'

const apartmentsPageMountElement = document.querySelector('.ApartmentsPage')

Vue.component('apartments-table', ApartmentsTable)

if (apartmentsPageMountElement) {
  new Vue({
    name: 'ApartmentsPageRoot',

    store,
  }).$mount(apartmentsPageMountElement)
}
