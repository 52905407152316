<template>
  <BContainer fluid>
    <BRow>
      <BCol>
        <IssuesTable
          :table-id="`issues-table-project-${project.id}`"
          :filters="tableFilters"
          :hide-fields="['project']"
        />
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import IssuesTable from '../components/IssuesTable'

export default {
  components: {
    IssuesTable,
  },

  props: {
    project: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
      default: 'open',
    },
  },

  computed: {
    statuses () {
      if (this.status === 'open') {
        return 'new,ongoing,waiting'
      } else if (this.status === 'oneyear') {
        return 'oneyear'
      } else if (this.status === 'closed') {
        return 'rejected,closed'
      }

      return null
    },

    tableFilters () {
      const filters = {
        projectStage: this.project.id,
      }

      if (this.statuses !== null) {
        filters.statuses = this.statuses
      }

      return filters
    },
  },
}
</script>
