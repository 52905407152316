<template>
  <div>
    <BButton
      :id="`popover-responsible-${$_uid}`"
      :size="buttonSize"
      :variant="buttonVariant"
      :block="buttonBlock"
    >
      {{ buttonLabel }}
    </BButton>

    <BPopover
      ref="popover"
      :target="`popover-responsible-${$_uid}`"
      triggers="click blur"
      placement="top"
      boundary="window"
      @shown="popoverShown"
    >
      <BForm
        inline
        @submit.prevent="submitForm"
      >
        <BInputGroup>
          <BFormSelect
            ref="formSelect"
            v-model="selectedPerson"
            :disabled="isBusy"
          >
            <option
              disabled
              :value="null"
            >
              Velg ansvarlig
            </option>

            <option
              v-for="person in availablePersons"
              :key="person.key"
              :value="person"
            >
              {{ person.name }} ({{ person.email }})
            </option>
          </BFormSelect>

          <BInputGroupAppend>
            <BButton
              v-if="isBusy"
              disabled
            >
              <b-spinner
                variant="light"
                small
              />
            </BButton>
            <BButton
              v-else
              type="submit"
              variant="primary"
            >
              Lagre
            </BButton>
          </BInputGroupAppend>
        </BInputGroup>
      </BForm>
    </BPopover>
  </div>
</template>

<script>
import Axios from 'axios'
export default {
  props: {
    currentPerson: {
      type: [String, Object],
      default: () => ({}),
    },
    issueId: {
      type: [Number, String],
      required: true,
    },
    availablePersons: {
      type: Array,
      default: () => ([]),
    },
    buttonVariant: {
      type: String,
      default: 'light-gray',
    },
    buttonSize: {
      type: String,
      default: 'sm',
    },
    buttonBlock: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    person: null,
    selectedPerson: null,
    isBusy: false,
  }),

  computed: {
    buttonLabel () {
      if (this.person !== null) {
        return this.person.name
      }

      return 'Velg ansvarlig'
    },
  },

  mounted () {
    if (this.currentPerson !== null && this.currentPerson !== '') {
      const currentPerson = this.availablePersons.find(person => {
        return person.id === this.currentPerson.id
      })

      if (typeof currentPerson !== 'undefined') {
        this.person = currentPerson
        this.selectedPerson = currentPerson
      }
    }
  },

  methods: {
    popoverShown () {
      const formSelect = this.$refs.formSelect

      if (formSelect) {
        formSelect.$el.focus()
      }
    },

    submitForm () {
      this.isBusy = true
      if (this.selectedPerson && this.selectedPerson !== this.person) {
        this.isBusy = true

        Axios.put(`/api/issues/${this.issueId}/`, {
          responsiblePerson: this.selectedPerson.id,
        }).then(response => {
          this.person = this.selectedPerson
          this.$refs.popover.$emit('close')
        }).finally(response => {
          this.isBusy = false
        })
      }
    },
  },
}
</script>
