<template>
  <div class="IssueDetailsStatusBar">
    <div class="IssueDetailsStatusBar__column">
      <h2 class="IssueDetailsStatusBar__heading">
        Status
      </h2>
      <IssueStatusSelector
        :current-status="issue.status"
        :issue-id="issue.id"
        button-variant="primary"
        button-size="md"
        button-block
      />
    </div>
    <div class="IssueDetailsStatusBar__column">
      <h2 class="IssueDetailsStatusBar__heading">
        Sakstype
      </h2>
      <IssueTypeSelector
        :current-type="issue.type"
        :issue-id="issue.id"
        button-size="md"
        button-block
      />
    </div>
    <div class="IssueDetailsStatusBar__column">
      <h2 class="IssueDetailsStatusBar__heading">
        Fagområde
      </h2>
      <IssueFieldSelector
        :current-field="issue.field"
        :issue-id="issue.id"
        button-size="md"
        button-block
      />
    </div>
    <div class="IssueDetailsStatusBar__column">
      <h2 class="IssueDetailsStatusBar__heading">
        Ansvarlig
      </h2>
      <IssueResponsiblePersonSelector
        :current-person="issue.responsiblePerson"
        :issue-id="issue.id"
        :available-persons="issue.projectStage.contactPersons"
        button-size="md"
        button-block
      />
    </div>
  </div>
</template>

<script>
import IssueResponsiblePersonSelector from './IssueResponsiblePersonSelector'
import IssueFieldSelector from './IssueFieldSelector'
import IssueTypeSelector from './IssueTypeSelector'
import IssueStatusSelector from './IssueStatusSelector'

export default {
  components: {
    IssueStatusSelector,
    IssueTypeSelector,
    IssueFieldSelector,
    IssueResponsiblePersonSelector,
  },

  props: {
    issue: {
      type: Object,
      required: true,
    },
  },
}
</script>
