<template>
  <div>
    <div class="TableActions">
      <BForm
        class="TableActions__search"
        inline
        @submit.prevent="refresh"
      >
        <label
          :for="`filter-input-${$_uid}`"
          class="TableActions__searchLabel"
        >
          Søk:
        </label>
        <BFormInput
          :id="`filter-input-${$_uid}`"
          v-model="filter"
          debounce="250"
          placeholder="Minst 2 tegn"
        />
      </BForm>

      <BDropdown
        class="TableActions__button"
        right
      >
        <template v-slot:button-content>
          <FontAwesomeIcon :icon="['fas', 'columns']" />
          Vis/skjul
        </template>

        <BDropdownForm>
          <BFormCheckboxGroup
            v-model="visibleFields"
            class="text-nowrap"
            :options="visibleFieldsOptions"
            stacked
          />
        </BDropdownForm>
      </BDropdown>

      <BButton
        v-if="enableCreateReportButton"
        class="TableActions__button"
        :href="createReportHref"
      >
        <FontAwesomeIcon :icon="['fas', 'chart-line']" />
        Lag rapport
      </BButton>

      <BDropdown
        v-if="enableExports"
        class="TableActions__button"
        right
      >
        <template v-slot:button-content>
          <FontAwesomeIcon :icon="['fas', 'file-export']" />
          Eksporter
        </template>

        <BDropdownItem
          download
          target="_blank"
          :href="exportThisPageUrl"
          @click="exportToast"
        >
          Denne siden ({{ currentPageItems }} saker)
        </BDropdownItem>

        <BDropdownItem
          download
          target="_blank"
          :href="exportAllUrl"
          @click="exportToast"
        >
          Alle sider ({{ totalItems }} saker)
        </BDropdownItem>
      </BDropdown>
    </div>

    <BTable
      :id="tableId"
      ref="issueTable"
      striped
      :busy.sync="isBusy"
      :items="dataProvider"
      :fields="fieldsFiltered"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :filter="filter"
      responsive
    >
      <template v-slot:table-busy>
        <div class="text-center my-4">
          <BSpinner class="d-block mx-auto mb-2" />
          <strong>Laster…</strong>
        </div>
      </template>

      <template v-slot:cell(id)="data">
        <a :href="`/issues/${data.value}`">
          {{ data.value }}
        </a>
      </template>

      <template v-slot:cell(date)="data">
        {{ formatDate(data.value) }}
      </template>

      <template v-slot:cell(updated)="data">
        {{ formatUpdated(data.value) }}
      </template>

      <template v-slot:cell(project)="data">
        <a :href="`/projects/${data.value.id}/`">{{ data.value.name }}</a>
      </template>

      <template v-slot:cell(apartment)="data">
        <a :href="`/apartments/${data.value.id}/`">{{ data.value.aptNumber }}</a>
      </template>

      <template v-slot:cell(category)="data">
        {{ data.value.name }}
      </template>

      <template v-slot:cell(field)="data">
        <IssueFieldSelector
          :current-field="data.value"
          :issue-id="data.item.id"
        />
      </template>

      <template v-slot:cell(type)="data">
        <IssueTypeSelector
          :current-type="data.value"
          :issue-id="data.item.id"
        />
      </template>

      <template v-slot:cell(status)="data">
        <IssueStatusSelector
          :current-status="data.value"
          :issue-id="data.item.id"
        />
      </template>

      <template v-slot:cell(responsiblePerson)="data">
        <IssueResponsiblePersonSelector
          :current-person="data.value"
          :issue-id="data.item.id"
          :available-persons="data.item.project.contactPersons"
        />
      </template>

      <template v-slot:cell(closed)="data">
        <ClosingTimeIndicator
          :status="data.value.status"
          :days="data.value.days"
        />
      </template>
    </BTable>

    <BPagination
      v-model="currentPage"
      :per-page="perPage"
      :total-rows="totalItems"
      :aria-controls="tableId"
    />
  </div>
</template>

<script>
import Axios from 'axios'
import Dayjs from 'dayjs'
import 'dayjs/locale/nb'
import buildQueryString from '@/shared/utils/buildQueryString'
import { mapMutations, mapGetters } from 'vuex'

import IssueFieldSelector from '../components/IssueFieldSelector'
import IssueTypeSelector from '../components/IssueTypeSelector'
import IssueStatusSelector from '../components/IssueStatusSelector'
import IssueResponsiblePersonSelector from '../components/IssueResponsiblePersonSelector'
import ClosingTimeIndicator from '../components/ClosingTimeIndicator'

export default {
  components: {
    IssueFieldSelector,
    IssueTypeSelector,
    IssueStatusSelector,
    IssueResponsiblePersonSelector,
    ClosingTimeIndicator,
  },

  props: {
    tableId: {
      type: String,
      default: 'issues-table',
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    hideFields: {
      type: Array,
      default: () => ([]),
    },
    syncWithUrl: {
      type: Boolean,
      default: false,
    },
    enableExports: {
      type: Boolean,
      default: false,
    },
    enableCreateReportButton: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    isBusy: false,
    perPage: 25,
    currentPage: 1,
    totalItems: 0,
    sortBy: null,
    sortDesc: true,
    filter: null,
    currentFilter: null,
    query: null,
    fields: [
      {
        key: 'id',
        label: '#',
        sortable: true,
      },
      {
        key: 'date',
        label: 'Dato',
        sortable: true,
      },
      {
        key: 'updated',
        label: 'Sist oppdatert',
        sortable: true,
      },
      {
        key: 'project',
        label: 'Prosjekt',
        sortable: true,
      },
      {
        key: 'apartment',
        label: 'Leilighet',
        sortable: true,
      },
      {
        key: 'customerName',
        label: 'Navn',
        sortable: true,
      },
      {
        key: 'category',
        label: 'Kategori',
        sortable: true,
      },
      {
        key: 'description',
        label: 'Beskrivelse',
        sortable: false,
        thStyle: {
          minWidth: '18rem',
        },
      },
      {
        key: 'field',
        label: 'Fagområde',
        sortable: true,
      },
      {
        key: 'type',
        label: 'Sakstype',
        sortable: true,
      },
      {
        key: 'status',
        label: 'Status',
        sortable: true,
      },
      {
        key: 'responsiblePerson',
        label: 'Ansvarlig',
        sortable: true,
      },
      {
        key: 'closed',
        label: 'Lukketid',
        sortable: true,
      },
    ],
  }),

  computed: {
    exportThisPageUrl () {
      return `/api/issues/export?${this.query}`
    },

    exportAllUrl () {
      return `/api/issues/export/all?${this.query}`
    },

    fieldsFiltered () {
      return this.fields.filter(field => {
        return this.fieldIsVisible(field.key)
      })
    },

    visibleFieldsOptions () {
      return this.fields.map(field => {
        return {
          value: field.key,
          text: field.label,
        }
      })
    },

    visibleFields: {
      get () {
        return this.getVisibleFields(this.tableId)
      },
      set (value) {
        this.setTableVisibleFields({
          table: this.tableId,
          fields: value,
        })
      },
    },

    currentPageItems () {
      return this.totalItems > this.perPage ? this.perPage : this.totalItems
    },

    createReportHref () {
      return `/reports?${this.query}`
    },

    ...mapGetters({
      getVisibleFields: 'settings/tableVisibleFields',
    }),
  },

  created () {
    if (!this.syncWithUrl) {
      return
    }

    const filters = {}

    new URLSearchParams(location.search).forEach((value, key) => {
      filters[key] = value
    })

    this.$emit('update:filters', filters)
  },

  mounted () {
    Dayjs.locale('nb')

    if (!this.visibleFields.length) {
      const fields = this.fields.filter(field => {
        return !this.hideFields.includes(field.key)
      }).map(field => {
        return field.key
      })

      this.visibleFields = fields
    }
  },

  methods: {
    dataProvider () {
      this.refreshQueryString()

      const request = Axios.get(`/api/issues?${this.query}`)

      return request.then(response => {
        const data = response.data.data
        const items = data.items

        this.totalItems = data.totalItems
        this.perPage = data.itemsPerPage
        this.currentPage = data.page

        return items
      }).catch(error => {
        this.$bvToast.toast('Kunne ikke hente saker.', {
          variant: 'danger',
          title: 'Noe gikk galt',
        })

        console.error(error)

        return []
      })
    },

    buildQueryString (data) {
      return buildQueryString(data)
    },

    formatDate (data) {
      return Dayjs(data).format('DD/MM/YYYY')
    },

    formatUpdated (data) {
      return Dayjs(data).format('DD/MM/YYYY HH:mm')
    },

    fieldIsVisible (key) {
      return this.visibleFields.some(visibleField => {
        return visibleField === key
      })
    },

    exportToast () {
      this.$bvToast.toast('Eksport av saker har startet. Dersom du har valgt å eksportere mange saker kan det ta opptil flere minutter.', {
        variant: 'success',
        title: 'Eksport startet',
      })
    },

    refreshQueryString () {
      const query = {
        ...this.filters,
      }

      query.page = this.currentPage

      if (this.sortBy) {
        query.sortBy = this.sortBy
        query.sortDirection = this.sortDesc ? 'desc' : 'asc'
      }

      if (this.filter?.length > 1) {
        query.filter = this.filter

        if (query.filter !== this.currentFilter) {
          query.page = 1
          this.currentFilter = this.filter
        }
      }

      this.query = this.buildQueryString(query)

      if (this.syncWithUrl) {
        window.history.replaceState(null, null, `${location.pathname}?${this.query}`)
      }
    },

    refresh () {
      this.$refs.issueTable.refresh()
    },

    ...mapMutations({
      setTableVisibleFields: 'settings/setTableVisibleFields',
    }),
  },
}
</script>
