<template>
  <div class="IssueHistoryTimeline">
    <div
      v-for="(item, key) in items"
      :key="key"
      class="IssueHistoryTimeline__item"
    >
      <div class="IssueHistoryTimeline__timestamp">
        {{ formatDate(item.date) }}
      </div>

      <div class="IssueHistoryTimeline__indicator">
        <FontAwesomeIcon :icon="['fas', item.icon]" />
      </div>

      <BCard
        class="IssueHistoryTimeline__itemCard"
        :title="item.title"
        title-tag="h6"
      >
        <BCardText>
          {{ item.description }}
        </BCardText>
      </BCard>
    </div>
  </div>
</template>

<script>
import Dayjs from 'dayjs'

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  methods: {
    formatDate (data) {
      return Dayjs(data).format('DD/MM/YYYY HH:mm')
    },
  },
}
</script>
