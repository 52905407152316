import Vue from 'vue'

import store from '../config/vuex/store'

import NavbarSearch from '../components/NavbarSearch'

Vue.component('navbar-search', NavbarSearch)

const mastheadMountElement = document.querySelector('.Masthead')

if (mastheadMountElement) {
  new Vue({
    name: 'MastheadRoot',

    store,

    data () {
      return {
        isRefreshingData: false,
      }
    },

    methods: {
      async refreshData () {
        this.isRefreshingData = true

        await Promise.all([
          this.$store.dispatch('apartments/refresh'),
          this.$store.dispatch('issues/refresh'),
          this.$store.dispatch('superUsers/refresh'),

          this.$store.dispatch('messages/reset'),
        ]).then((values) => {
          this.isRefreshingData = false
        })
      },
    },
  }).$mount(mastheadMountElement)
}
